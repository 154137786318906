import { http, createConfig } from "wagmi";
import { arbitrum } from "wagmi/chains";
import { walletConnect } from "wagmi/connectors";

export const metadata = {
  name: import.meta.env.VITE_APP_APP_NAME,
  description: "",
  url: import.meta.env.VITE_APP_DOMAIN_URL,
  icons: [],
};

const projectId = import.meta.env.VITE_APP_WALLETCONNECT_PROJECT_ID;

export const wagmiConfig = createConfig({
  chains: [arbitrum],
  transports: {
    [arbitrum.id]: http(
      `${import.meta.env.VITE_ARBITRUM_MAINNET_RPC_URL}/${
        import.meta.env.VITE_APP_ARBITRUM_PROVIDER_API_KEY
      }`
    ),
  },
  connectors: [
    walletConnect({
      projectId,
      metadata,
      disableProviderPing: true,
      showQrModal: false,
      relayUrl: undefined,
    }),
  ],
});

declare module "wagmi" {
  interface Register {
    config: typeof wagmiConfig;
  }
}
