import Copy from "@/assets/icons/svgComponents/Copy";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { Button } from "./ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

interface CopyButtonProps {
  message: string | number;
  className?: string;
}

export default function CopyButton({ message, className }: CopyButtonProps) {
  const copy = useCopyToClipboard();
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            className={className}
            variant="ghost"
            size="icon"
            onClick={() => copy(message)}
          >
            <Copy fill="white" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>copy</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
