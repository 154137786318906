import * as SentryBrowser from "@sentry/browser";
import * as Sentry from "@sentry/react";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { queryClient } from "./lib/query-client";
import { useAuth } from "./providers/AuthProvider";
import { initialAuthData } from "./providers/AuthProvider/data";
import { routeTree } from "./routeTree.gen";

const router = createRouter({
  routeTree,
  context: {
    queryClient,
    auth: initialAuthData,
  },
  defaultPreload: "intent",
  defaultPreloadStaleTime: 0,
});

// Register router for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const enableSentry = import.meta.env.VITE_ENABLE_SENTRY === "true";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    SentryBrowser.captureConsoleIntegration(),
    SentryBrowser.httpClientIntegration(),
  ],
  attachStacktrace: true,
  enableTracing: true,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/api\.gqpay\.io\/graphql/],
  maxBreadcrumbs: 100,
  debug: !enableSentry,
  enabled: enableSentry,
});

export default function App() {
  const auth = useAuth();

  return (
    <Sentry.ErrorBoundary showDialog>
      <RouterProvider
        router={router}
        context={{
          auth,
          queryClient,
        }}
      />
    </Sentry.ErrorBoundary>
  );
}
