import BottomNavigation from "@/components/BottomNavigation";
import DashboardMobileNav from "@/components/DashboardMobileNav";
import SettingsMobileNav from "@/components/SettingsMobileNav";
import SideBar from "@/components/SideBar";
import useMatchRoutes from "@/hooks/useMatchRoutes";
import { cn } from "@/lib/utils";
import { Outlet } from "@tanstack/react-router";

const AuthenticatedRoutesLayout = () => {
  const {
    isSettingsRoutes,
    isDashboardRoute,
    isRewardsRoute,
    isCashbackRoute,
  } = useMatchRoutes();

  return (
    <div className="relative flex h-dvh overflow-hidden">
      <header
        className={cn(
          "fixed z-50 w-full bg-[#F5F8FE]/80 backdrop-blur sm:hidden",
          {
            hidden: !(isSettingsRoutes || isDashboardRoute),
          }
        )}
      >
        {isSettingsRoutes && <SettingsMobileNav />}
        {isDashboardRoute && <DashboardMobileNav />}
      </header>
      <SideBar />

      <main
        id="dashboard-screen"
        className={cn(
          "min-h-dvh w-full overflow-y-auto overflow-x-hidden bg-white pb-20 transition-colors dark:bg-[#1A131C] sm:bg-[#F6F6F6] sm:pb-10",
          {
            "bg-[#FFFFFD]": isRewardsRoute,
            "pt-[68px] sm:pt-0": isDashboardRoute,
          }
        )}
      >
        <Outlet />
      </main>

      <BottomNavigation />
    </div>
  );
};

export default AuthenticatedRoutesLayout;
