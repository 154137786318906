import HomeSvg from "@/assets/icons/svgComponents/HomeSvg";
import Settings from "@/assets/icons/svgComponents/Settings";
import useMatchRoutes from "@/hooks/useMatchRoutes";
import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { GiftIcon, PercentCircle } from "lucide-react";

export default function BottomNavigation() {
  const {
    isSettingsRoute,
    isSettingsRoutes,
    isDashboardRoute,
    isRewardsRoute,
    isCashbackRoute,
  } = useMatchRoutes();

  return (
    <footer className="fixed bottom-0 z-50 flex w-full justify-between bg-white px-6 py-3 pb-5 transition-colors [filter:drop-shadow(0px_0px_16px_rgba(0,0,0,0.1))] sm:hidden dark:bg-dashboardDark">
      <Link
        to="/my/dashboard"
        className={cn(
          "flex flex-col items-center gap-y-1 font-medium font-poppins text-[#1E1F4B80] text-xs transition-colors dark:text-white",
          {
            "text-[#4766F9] dark:text-[#4766F9]": isDashboardRoute,
          }
        )}
      >
        <HomeSvg
          className="h-5 w-5"
          stroke="currentColor"
          strokeWidth="1.25px"
        />
        Home
      </Link>

      <Link
        to="/rewards"
        className={cn(
          "flex flex-col items-center gap-y-1 font-medium font-poppins text-[#1E1F4B80] text-xs transition-colors dark:text-white",
          {
            "text-[#4766F9] dark:text-[#4766F9]": isRewardsRoute,
          }
        )}
      >
        <GiftIcon
          className="h-5 w-5"
          stroke="currentColor"
          strokeWidth="1.25px"
        />
        Rewards
      </Link>

      <Link
        to="/cashback"
        className={cn(
          "flex flex-col items-center gap-y-1 font-medium font-poppins text-[#1E1F4B80] text-xs transition-colors dark:text-white",
          {
            "text-[#4766F9] dark:text-[#4766F9]": isCashbackRoute,
          }
        )}
      >
        <PercentCircle
          className="h-5 w-5"
          stroke="currentColor"
          strokeWidth="1.25px"
        />
        Cashbacks
      </Link>

      <Link
        to="/my/settings"
        className={cn(
          "flex flex-col items-center gap-y-1 font-medium font-poppins text-[#1E1F4B80] text-xs transition-colors dark:text-white",
          {
            "text-[#4766F9] dark:text-[#4766F9]":
              isSettingsRoute || isSettingsRoutes,
          }
        )}
      >
        <Settings
          className="h-5 w-5"
          stroke="currentColor"
          strokeWidth="1.25px"
        />
        Settings
      </Link>
    </footer>
  );
}
