import {
  AUTH_TOKEN_STORAGE_KEY,
  CONNECTED_WALLET,
  HAS_SEEN_REGISTER_POPUP,
} from "@/constants/storage-constants";

declare global {
  interface Window {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    rainbow: any;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    trustWallet: any;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    exodus: any;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    tokenpocket: any;
  }
}

export function truncateEthAddress(address: string, length = 4) {
  // Captures 0x + 4 characters, then the last 4 characters.
  const truncateRegex = `^(0x[a-zA-Z0-9]{${length}})[a-zA-Z0-9]+([a-zA-Z0-9]{${length}})$`;

  const match = address.match(new RegExp(truncateRegex));
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
}

export const localStorageItemsMaintained = [
  AUTH_TOKEN_STORAGE_KEY,
  HAS_SEEN_REGISTER_POPUP,
  CONNECTED_WALLET,
];

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export function removeInstalledWallet() {
  const featuredWalletIds = [
    "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369", // Rainbow
    "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa", // Coinbase
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", // Trust
    "e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4", // Exodus
    "20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66", // Token Pocket
    "c29c9237e92bc18e141e52aba3aa6d04b1afbe9952a0ab2f96dbd8653645c1df", // AnCrypto wallet
    "84b43e8ddfcd18e5fcb5d21e7277733f9cccef76f7d92c836d0e481db0c70c04", // Blockchain.com
    "bc949c5d968ae81310268bf9193f9c9fb7bb4e1283e1284af8f2bd4992535fd6", // Argent
  ];
  if (window?.rainbow) {
    const index = featuredWalletIds.indexOf(
      "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369"
    );
    if (index > -1) {
      featuredWalletIds.splice(index, 1);
    }
  }
  if (window?.coinbaseWalletExtension) {
    const index = featuredWalletIds.indexOf(
      "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa"
    );
    if (index > -1) {
      featuredWalletIds.splice(index, 1);
    }
  }
  if (window?.trustWallet) {
    const index = featuredWalletIds.indexOf(
      "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0"
    );
    if (index > -1) {
      featuredWalletIds.splice(index, 1);
    }
  }

  if (window?.exodus) {
    const index = featuredWalletIds.indexOf(
      "e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4"
    );
    if (index > -1) {
      featuredWalletIds.splice(index, 1);
    }
  }
  if (window?.tokenpocket) {
    const index = featuredWalletIds.indexOf(
      "20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66"
    );
    if (index > -1) {
      featuredWalletIds.splice(index, 1);
    }
  }

  return featuredWalletIds;
}
