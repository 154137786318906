import { Skeleton } from "./ui/skeleton";

interface UserDefaultImageProps {
  seed: string | number;
  size: number;
  className?: string;
  image?: string | null | undefined;
  isLoading?: boolean | undefined;
}

export function UserDefaultImage({
  seed,
  size,
  className,
  image,
  isLoading,
}: UserDefaultImageProps) {
  if (isLoading) {
    return (
      <Skeleton className="mb-5 h-[96px] w-[96px] rounded-full dark:bg-neutral-600" />
    );
  }
  return (
    <img
      className={className}
      src={
        image
          ? image
          : `https://api.dicebear.com/7.x/personas/svg?seed=${seed}&flip=true&backgroundColor=7C66FF,531479&backgroundType=gradientLinear&backgroundRotation=360,90,0,270,180&body=squared,checkered&facialHairProbability=0&hair=extraLong,fade,shortCombover,cap&mouth=bigSmile,smile&nose=mediumRound&size=${size}`
      }
      alt="avatar"
      width={size}
      height={size}
    />
  );
}
