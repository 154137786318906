import useMatchRoutes from "@/hooks/useMatchRoutes";
import { Link, useRouterState } from "@tanstack/react-router";
import { ChevronLeft } from "lucide-react";

export default function SettingsMobileNav() {
  const routerState = useRouterState();
  const { isSettingsRoutes } = useMatchRoutes();

  if (!isSettingsRoutes) {
    return null;
  }

  const path = routerState.location.pathname.split("/").pop();

  return (
    <nav className="flex gap-x-8 px-7 py-5">
      <Link to="/my/settings">
        <ChevronLeft stroke="#000" />
      </Link>

      <p className="font-medium font-poppins text-[#3D3D3D] text-base capitalize">
        {path}
      </p>
    </nav>
  );
}
