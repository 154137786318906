import "./globals.css";

import "@/lib/polyfills";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { IKContext } from "imagekitio-react";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { analytics } from "./ananlytics";
import { Toaster } from "./components/ui/sonner";
import { useCountriesQueryKey } from "./hooks/api/useCountries";
import { queryClient } from "./lib/query-client";
import AuthProvider from "./providers/AuthProvider";
import LangProvider from "./providers/LangProvider";
import { ThemeProvder } from "./providers/ThemeProvider";
import Web3Provider from "./providers/Web3Provider";

const persister = createSyncStoragePersister({ storage: window.localStorage });

const container = document.getElementById("root")!;
const root = createRoot(container);

analytics.init();

root.render(
  <React.StrictMode>
    <LangProvider>
      <Web3Provider>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister,
            dehydrateOptions: {
              shouldDehydrateQuery(query) {
                return query.queryKey === useCountriesQueryKey;
              },
            },
            maxAge: 1000 * 60 * 60 * 24,
          }}
        >
          <IKContext
            urlEndpoint={`${import.meta.env.VITE_APP_IMAGEKIT_URL_ENDPOINT}/${
              import.meta.env.VITE_APP_IMAGEKIT_FOLDER_PATH
            }`}
          >
            <ThemeProvder>
              <AuthProvider>
                <App />
              </AuthProvider>
            </ThemeProvder>
            <Toaster duration={2000} />
          </IKContext>
        </PersistQueryClientProvider>
      </Web3Provider>
    </LangProvider>
  </React.StrictMode>
);
