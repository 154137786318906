import { availableLocales, localeNames } from "@/data/locale-i18n";
import { cn } from "@/lib/utils";
import { useLang } from "@/providers/LangProvider";
import type { AvailableLocales } from "@/types/general";
import { X } from "lucide-react";
import { Button } from "./ui/button";
import { Dialog, DialogClose, DialogContent, DialogHeader } from "./ui/dialog";

interface LanguageSelectorDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function LanguageSelectorDialog({
  isOpen,
  onClose,
}: LanguageSelectorDialogProps) {
  const { locale, setLocale } = useLang();

  const onLocaleSelect = (locale: string) => () => {
    setLocale(locale as AvailableLocales);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-lg rounded-3xl bg-white shadow-[1px_10px_11px_0px_rgba(38,38,38,0.06)]">
        <DialogHeader className="relative">
          <p className="text-center font-medium font-poppins text-base text-black dark:text-white">
            Choose Language
          </p>
          <DialogClose className="absolute top-0 right-0">
            <X stroke="#6236FF" />
          </DialogClose>
        </DialogHeader>

        <ul className="flex w-full flex-col">
          {availableLocales.map((language) => (
            <li key={language} className="w-full">
              <Button
                variant="primary"
                size="icon"
                className={cn(
                  "w-full justify-between rounded-xl bg-transparent px-6 py-3 dark:bg-transparent hover:bg-transparent",
                  {
                    "bg-[#6332CB] hover:bg-[#6332CB] dark:bg-[#6332CB]":
                      locale === language,
                  }
                )}
                onClick={onLocaleSelect(language)}
              >
                <p
                  className={cn(
                    "font-poppins text-base text-black dark:text-white",
                    {
                      "font-medium text-white": locale === language,
                    }
                  )}
                >
                  {localeNames[language]}
                </p>
              </Button>
            </li>
          ))}
        </ul>
      </DialogContent>
    </Dialog>
  );
}
