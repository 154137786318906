import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { RewardsSkeleton } from "./-MyRewards";

export default function PendingComponent() {
  return (
    <>
      <section aria-label="gradient info section" className="rewards" />

      <section className="-top-10 relative flex snap-x snap-mandatory snap-always scroll-pl-[8%] gap-8 overflow-auto px-[8%] pb-10">
        <div
          className={cn(
            "flex snap-start justify-between rounded-2xl border-2 border-[#8B63FF] bg-white px-9 py-3 shadow-[0px_0px_14.1px_0px_#9A36FF] backdrop-blur-sm dark:bg-[#2B1F2E]",
            "h-20 min-w-[420px] mdx:min-w-[512px]"
          )}
        >
          <div className="flex flex-col justify-between">
            <div className="flex items-center gap-x-2">
              <Skeleton className="h-6 w-6 rounded-full bg-neutral-300 dark:bg-neutral-600" />
              <Skeleton className="h-4 w-16 bg-neutral-300 dark:bg-neutral-600" />
            </div>

            <Skeleton className="h-4 bg-neutral-300 dark:bg-neutral-600" />
          </div>
        </div>

        <div
          className={cn(
            "flex snap-start justify-between rounded-2xl border-2 border-[#8B63FF] bg-white px-9 py-3 shadow-[0px_0px_14.1px_0px_#9A36FF] backdrop-blur-sm dark:bg-[#2B1F2E]",
            "h-20 min-w-[420px] mdx:min-w-[512px]"
          )}
        >
          <div className="flex flex-col justify-between">
            <div className="flex items-center gap-x-2">
              <Skeleton className="h-6 w-6 rounded-full bg-neutral-300 dark:bg-neutral-600" />
              <Skeleton className="h-4 w-16 bg-neutral-300 dark:bg-neutral-600" />
            </div>

            <Skeleton className="h-4 bg-neutral-300 dark:bg-neutral-600" />
          </div>
        </div>
      </section>

      <section className="mx-auto flex w-10/12 flex-col gap-4 lg:flex-row">
        <div className="flex items-center justify-between rounded-2xl bg-[#F4F1FF] px-6 py-7 lg:basis-2/3 dark:bg-[#2B1F2E]">
          <div>
            <Skeleton className="mb-4 h-4 bg-neutral-400 dark:bg-neutral-600" />
            <Skeleton className="h-24 w-[250px] bg-neutral-300 dark:bg-neutral-600" />
          </div>
        </div>

        <div className="space-y-4 rounded-2xl bg-[#F4F1FF] p-6 lg:basis-1/3 dark:bg-[#2B1F2E]">
          <div className="flex justify-between rounded-lg bg-white px-6 py-3 dark:bg-[#47344B]">
            <Skeleton className="h-4 w-16 bg-neutral-300 dark:bg-neutral-600" />
            <Skeleton className="h-4 w-16 bg-neutral-300 dark:bg-neutral-600" />
          </div>

          <div className="flex gap-3">
            <Skeleton className="h-10 basis-1/2 bg-neutral-300 dark:bg-neutral-600" />
            <Skeleton className="h-10 basis-1/2 bg-neutral-300 dark:bg-neutral-600" />
          </div>
        </div>
      </section>

      <div className="h-10" />

      <RewardsSkeleton className="mx-auto w-10/12" />
    </>
  );
}
