import type { SVGProps } from "react";

const Identity = ({
  pathFillColor,
  ...props
}: SVGProps<SVGSVGElement> & { pathFillColor?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      {...props}
    >
      <title>Identity</title>
      <path
        d="M5.3125 6.375C3.56469 6.375 2.125 7.81469 2.125 9.5625V24.4375C2.125 26.1853 3.56469 27.625 5.3125 27.625H28.6875C30.4353 27.625 31.875 26.1853 31.875 24.4375V9.5625C31.875 7.81469 30.4353 6.375 28.6875 6.375H5.3125ZM5.3125 8.5H28.6875C29.2889 8.5 29.75 8.96112 29.75 9.5625V24.4375C29.75 25.0389 29.2889 25.5 28.6875 25.5H5.3125C4.71112 25.5 4.25 25.0389 4.25 24.4375V9.5625C4.25 8.96112 4.71112 8.5 5.3125 8.5ZM11.6875 10.625C9.35 10.625 7.4375 12.5375 7.4375 14.875C7.4375 16.0576 7.94431 17.1243 8.73269 17.8967C8.00947 18.3848 7.41656 19.0423 7.00563 19.8119C6.59469 20.5816 6.3782 21.44 6.375 22.3125H8.5C8.5 20.5402 9.91525 19.125 11.6875 19.125C13.4598 19.125 14.875 20.5402 14.875 22.3125H17C16.9968 21.44 16.7803 20.5816 16.3694 19.8119C15.9584 19.0423 15.3655 18.3848 14.6423 17.8967C15.4307 17.1243 15.9375 16.0586 15.9375 14.875C15.9375 12.5375 14.025 10.625 11.6875 10.625ZM19.125 11.6875V13.8125H27.625V11.6875H19.125ZM11.6875 12.75C12.8743 12.75 13.8125 13.6882 13.8125 14.875C13.8125 16.0618 12.8743 17 11.6875 17C10.5007 17 9.5625 16.0618 9.5625 14.875C9.5625 13.6882 10.5007 12.75 11.6875 12.75ZM19.125 15.9375V18.0625H27.625V15.9375H19.125ZM19.125 20.1875V22.3125H24.4375V20.1875H19.125Z"
        fill={pathFillColor ?? "white"}
      />
    </svg>
  );
};

export default Identity;
