export const availableLocales = [
  "en",
  "bn",
  "zh",
  "ko",
  "ms",
  "th",
  "vi",
] as const;

export const localeNames = {
  en: "English",
  bn: "Bengali",
  zh: "Chinese",
  ko: "Korean",
  ms: "Malay",
  th: "Thai",
  vi: "Vietnamese",
} as const;

export const localeNameShortForms = {
  en: "Eng",
  bn: "Ben",
  zh: "Ch",
  ko: "Ko",
  ms: "Malay",
  th: "Th",
  vi: "Vi",
} as const;
