import { ConditionsSvg } from "@/assets/icons/svgComponents/settings/ConditionSvg";
import { ContactsSvg } from "@/assets/icons/svgComponents/settings/ContactsSvg";
import DebitCardSvg from "@/assets/icons/svgComponents/settings/DebitCardSvg";
import IbanIcon from "@/assets/icons/svgComponents/settings/IbanIcon";
import Identity from "@/assets/icons/svgComponents/settings/Identity";
import type { ValidRoutes } from "@/types/valid-routes";
import type { SVGProps } from "react";
import { FormattedMessage } from "react-intl";

export const settingsNavItems: Array<{
  name: JSX.Element | string;
  href: ValidRoutes;
  image: React.ComponentType<
    SVGProps<SVGSVGElement> & { pathFillColor?: string }
  >;
}> = [
  {
    name: <FormattedMessage id="identity" defaultMessage="Identity" />,
    href: "/my/settings/identity",
    image: Identity,
  },
  {
    name: <FormattedMessage id="debit_card" defaultMessage="Debit Card" />,
    href: "/my/settings/debit-card",
    image: DebitCardSvg,
  },
  {
    name: "IBAN",
    href: "/my/settings/iban",
    image: IbanIcon,
  },
  {
    name: <FormattedMessage id="conditions" defaultMessage="Conditions" />,
    href: "/my/settings/conditions",
    image: ConditionsSvg,
  },
  {
    name: <FormattedMessage id="contact" defaultMessage="Contact" />,
    href: "/my/settings/contact",
    image: ContactsSvg,
  },
];
