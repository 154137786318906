import arbitrum from "@/assets/icons/svg/ARB.svg";
import cashbackIcon from "@/assets/icons/svg/cashback-sidebar-icon.svg";
import rewardsIcon from "@/assets/icons/svg/rewards-sidebar-icon.svg";
import Logout from "@/assets/icons/svgComponents/Logout";
import Pencil from "@/assets/images/ui-icons/Pencil";
import CopyButton from "@/components/CopyButton";
import GQpayLogoLink from "@/components/GQpayLogo";
import { UserDefaultImage } from "@/components/UserDefaultImage";
import { Button } from "@/components/ui/button";
import { queryKeys } from "@/constants/query-keys";
import useMatchRoutes from "@/hooks/useMatchRoutes";
import { graphqlClient } from "@/lib/query-client";
import { cn } from "@/lib/utils";
import { useAuth } from "@/providers/AuthProvider";
import type { ValidRoutes } from "@/types/valid-routes";
import { updateUserDocument } from "@/utils/documents";
import { uploadUserAvatar } from "@/utils/user";
import { truncateEthAddress } from "@/utils/utils";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link, useRouterState } from "@tanstack/react-router";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useCallback, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "sonner";
import { useBoolean } from "usehooks-ts";
import CurrencySideNavSection from "./CurrencySideNav";
import SettingsSideNav from "./SettingsSideNav";

const actions: Array<{
  name: JSX.Element;
  image: JSX.Element;
  href: ValidRoutes;
}> = [
  {
    name: <FormattedMessage id="rewards" defaultMessage="Rewards" />,
    image: (
      <img
        src={rewardsIcon}
        alt="metaverse"
        className="h-8 w-8"
        width="32"
        height="32"
      />
    ),
    href: "/rewards",
  },
  {
    name: <FormattedMessage id="cashback" defaultMessage="Cashback" />,
    image: (
      <img
        src={cashbackIcon}
        alt="metaverse"
        className="h-8 w-8"
        width="32"
        height="32"
      />
    ),
    href: "/cashback",
  },
  {
    name: <FormattedMessage id="settings" defaultMessage="Settings" />,
    image: (
      <Cog6ToothIcon className="h-8 w-8" fill="#6332CB" strokeWidth="1.25px" />
    ),
    href: "/my/settings/identity",
  },
];

export default function SideBar() {
  const { user, logout, address } = useAuth();
  const token = user?.nft?.tokenId ?? "";
  const { location } = useRouterState();

  const { isSettingsRoutes } = useMatchRoutes();

  const queryClient = useQueryClient();

  const asideRef = useRef<HTMLElement>(null);
  const imageRef = useRef<HTMLInputElement>(null);

  const [expanded, setExpanded] = useState(true);
  const { value: isUpdatingProfilePic, setValue } = useBoolean(false);

  const { mutateAsync: updateUser } = useMutation({
    mutationKey: ["updateUser"],
    mutationFn: (variables: { avatar: string }) =>
      graphqlClient.request({
        document: updateUserDocument,
        variables: { input: { avatar: variables.avatar } },
      }),
  });

  const handleSidebarExpansion = () => {
    setExpanded((prev) => {
      if (prev) {
        asideRef.current?.setAttribute("data-expanded", "false");
      } else {
        asideRef.current?.setAttribute("data-expanded", "true");
      }
      return !prev;
    });
  };

  const onLogout = useCallback(async () => {
    await logout();
    window.location.href = "/login";
  }, [logout]);

  const handleImageChange = async () => {
    if (!imageRef.current || !address) return;
    const file = imageRef.current.files![0];

    try {
      setValue(true);
      const uploadedUrl = await uploadUserAvatar(file, queryClient);
      await updateUser({ avatar: uploadedUrl });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.getUserQueryKey(address),
      });
      setValue(false);
      toast.success("Successfully updated user's image");
    } catch (error) {
      setValue(false);
      toast.error("Failed to update user's image");
    }
  };

  const handleClick = () => {
    if (!imageRef.current) return;
    imageRef.current.click();
  };

  return (
    <aside
      ref={asideRef}
      data-expanded={true}
      className="group relative z-[1] hidden h-full transition-colors [box-shadow:var(--sidebar-box-shadow)] sm:block data-[expanded=false]:w-36 data-[expanded=true]:w-80 dark:bg-dashboardDark"
    >
      <div className="flex max-h-dvh flex-col items-center overflow-y-auto px-4 pt-8 pb-6">
        <GQpayLogoLink />

        <div className="mb-5 flex flex-col items-center group-data-[expanded=false]:mt-16">
          <div className="relative">
            <UserDefaultImage
              seed={token}
              size={96}
              isLoading={isUpdatingProfilePic}
              image={user?.avatar}
              className="mb-6 aspect-square w-24 rounded-full"
            />
            <form className="mb-2">
              <input
                type="file"
                onChange={handleImageChange}
                className="hidden"
                accept="image/*"
                ref={imageRef}
              />
              <Button
                type="button"
                variant="plain"
                onClick={handleClick}
                className="-right-3 absolute bottom-6 flex h-9 w-9 items-center justify-center rounded-full bg-[#F0F0F0] p-0 transition-colors dark:bg-primary hover:bg-[#E4E4E4]"
              >
                <Pencil
                  width={14}
                  className="transition-colors dark:fill-white"
                />
              </Button>
            </form>
          </div>
          <p className="mb-3 text-center font-medium font-poppins text-[#2F2F30] text-base transition-colors dark:text-white">
            {user?.username}
          </p>
          <p className="mb-3 text-center font-bold font-poppins text-[#515151] text-lg transition-colors dark:text-white">
            #{token}
          </p>
          <div className="flex items-center data-[expanded:false]:gap-x-1 data-[expanded=true]:gap-x-2">
            <img
              src={arbitrum}
              alt="arbitrum logo"
              className="h-6 w-6"
              width="24"
              height="24"
            />
            <p className="font-medium font-poppins text-[#2F2F30] text-xs transition-colors dark:text-white">
              {truncateEthAddress(user?.walletAddress ?? "", expanded ? 4 : 2)}
            </p>
            <CopyButton
              className="text-black transition-colors dark:text-white"
              message={user?.walletAddress ?? ""}
            />
          </div>
        </div>

        {isSettingsRoutes ? (
          <SettingsSideNav />
        ) : (
          <>
            <CurrencySideNavSection />

            <ul className="flex w-full flex-col items-center">
              {actions.map((action) => (
                <li
                  key={action.href}
                  className="group-data-[expanded=true]:w-full"
                >
                  <Link
                    to={action.href}
                    search={{}}
                    preload={false}
                    className={cn(
                      "tooltip-hover relative flex items-center rounded-xl px-6 py-4 text-[#8D8D8E] transition-colors group-data-[expanded=true]:gap-x-4 group-data-[expanded=true]:px-5 dark:text-white",
                      {
                        "bg-[#F6F6F6] dark:text-white dark:bg-[#2B1F2E]":
                          action.href === location.pathname,
                      }
                    )}
                  >
                    {action.image}
                    <p className="hidden font-poppins text-base leading-[normal] group-data-[expanded=true]:block">
                      {action.name}
                    </p>
                    <span className="-top-2 -translate-x-1/2 absolute left-1/2 block whitespace-nowrap rounded-2xl bg-gray-700/80 px-2 py-1 text-xs opacity-0 group-data-[expanded=true]:hidden">
                      {action.name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>

            <Button
              variant="plain"
              className="tooltip-hover relative justify-start px-6 py-4 group-data-[expanded=true]:w-full group-data-[expanded=true]:gap-x-4 group-data-[expanded=true]:px-5"
              onClick={onLogout}
            >
              <Logout />{" "}
              <p className="hidden font-poppins text-base text-error group-data-[expanded=true]:block">
                <FormattedMessage id="logout" defaultMessage="Logout" />
              </p>
              <span className="-top-2 -translate-x-1/2 absolute left-1/2 block rounded-2xl bg-gray-700/80 px-2 py-1 text-xs opacity-0 group-data-[expanded=true]:hidden">
                <FormattedMessage id="logout" defaultMessage="Logout" />
              </span>
            </Button>

            <p className="mt-16 hidden whitespace-nowrap font-medium font-poppins text-[#A1A1A1] text-xs group-data-[expanded=true]:block">
              Copyright © GQpay
            </p>
          </>
        )}
      </div>

      <button
        type="button"
        className="-right-[19px] absolute top-28 flex h-20 w-20 items-center justify-end bg-white text-black transition-colors [clip-path:polygon(75%_0%,100%_25%,100%_75%,75%_100%)] dark:bg-[#302233] dark:text-white"
        onClick={handleSidebarExpansion}
      >
        {expanded ? <ChevronLeft /> : <ChevronRight />}
      </button>
    </aside>
  );
}
