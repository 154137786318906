import type { SVGProps } from "react";

const IbanIcon = ({
  pathFillColor,
  ...props
}: SVGProps<SVGSVGElement> & { pathFillColor?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      {...props}
    >
      <title>IBAN</title>
      <g clipPath="url(#clip0_86_1106)">
        <path
          d="M3.30561 12.8067L17.0001 4.93945L30.6945 12.8067C30.9103 12.9196 31.1615 12.9447 31.3954 12.8769C31.6294 12.809 31.828 12.6534 31.9499 12.4425C32.0718 12.2316 32.1075 11.9817 32.0496 11.7451C31.9916 11.5085 31.8445 11.3035 31.6389 11.1728L17.0001 2.75778L2.36116 11.1728C2.2483 11.2318 2.14854 11.3131 2.0679 11.4117C1.98725 11.5103 1.92738 11.6242 1.89189 11.7465C1.8564 11.8688 1.84602 11.9971 1.86138 12.1235C1.87674 12.25 1.91752 12.372 1.98127 12.4823C2.04501 12.5926 2.1304 12.6888 2.23231 12.7652C2.33421 12.8416 2.45052 12.8967 2.57424 12.927C2.69796 12.9573 2.82653 12.9622 2.95222 12.9416C3.07791 12.9209 3.19811 12.875 3.30561 12.8067Z"
          fill={pathFillColor ?? "#3D3D3D"}
        />
        <path
          d="M3.77734 24.5555C3.77734 24.806 3.87685 25.0463 4.05397 25.2234C4.23108 25.4005 4.47131 25.5 4.72179 25.5H29.2773C29.5278 25.5 29.7681 25.4005 29.9452 25.2234C30.1223 25.0463 30.2218 24.806 30.2218 24.5555C30.2218 24.3051 30.1223 24.0648 29.9452 23.8877C29.7681 23.7106 29.5278 23.6111 29.2773 23.6111H26.444V16.6505H24.5551V23.6111H17.944V16.6505H16.0551V23.6111H9.44401V16.6505H7.55512V23.6111H4.72179C4.47131 23.6111 4.23108 23.7106 4.05397 23.8877C3.87685 24.0648 3.77734 24.3051 3.77734 24.5555Z"
          fill={pathFillColor ?? "#3D3D3D"}
        />
        <path
          d="M4.74121 13.2222H29.2968V15.1111H4.74121V13.2222Z"
          fill={pathFillColor ?? "#3D3D3D"}
        />
        <path
          d="M31.1664 27.3889H2.83312C2.58263 27.3889 2.34241 27.4884 2.16529 27.6655C1.98818 27.8426 1.88867 28.0828 1.88867 28.3333C1.88867 28.5838 1.98818 28.824 2.16529 29.0012C2.34241 29.1783 2.58263 29.2778 2.83312 29.2778H31.1664C31.4169 29.2778 31.6572 29.1783 31.8343 29.0012C32.0114 28.824 32.1109 28.5838 32.1109 28.3333C32.1109 28.0828 32.0114 27.8426 31.8343 27.6655C31.6572 27.4884 31.4169 27.3889 31.1664 27.3889Z"
          fill={pathFillColor ?? "#3D3D3D"}
        />
        <path
          d="M20.9195 10.9367H23.9512L17.6139 7.29111C17.4991 7.22479 17.3688 7.18988 17.2362 7.18988C17.1035 7.18988 16.9732 7.22479 16.8584 7.29111L10.5117 10.9367H13.5434L17.2267 8.82111L20.9195 10.9367Z"
          fill={pathFillColor ?? "#3D3D3D"}
        />
      </g>
      <defs>
        <clipPath id="clip0_86_1106">
          <rect width="34" height="34" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IbanIcon;
