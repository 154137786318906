import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";

interface GQpayLogoLinkProps {
  className?: string;
}

export default function GQpayLogoLink({ className }: GQpayLogoLinkProps) {
  return (
    <Link to="/my/dashboard" className="pb-3">
      <GQpayLogo className={className} />
    </Link>
  );
}

export function GQpayLogo({ className }: GQpayLogoLinkProps) {
  return (
    <div className={cn("flex items-center text-[#2D2D2D]", className)}>
      <img
        src="/logo.png"
        alt="GQpay"
        className="h-10 w-auto transition-all dark:brightness-0 dark:invert"
      />
      {/* <p className="font-nats text-2xl">GQpay</p> */}
    </div>
  );
}
