import type { SVGProps } from "react";

export function ConditionsSvg({
  pathFillColor,
  ...props
}: SVGProps<SVGSVGElement> & { pathFillColor?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      {...props}
    >
      <title>Conditions</title>
      <path
        d="M5.66699 9.91666H9.91699M28.3337 9.91666H15.5837M28.3337 24.0833H24.0837M5.66699 24.0833H18.417M5.66699 17H28.3337"
        stroke={pathFillColor ?? "black"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
