import { AUTH_TOKEN_STORAGE_KEY } from "@/constants/storage-constants";
import { localStorageItemsMaintained } from "@/utils/utils";
import { QueryClient } from "@tanstack/react-query";
import { ClientError, GraphQLClient } from "graphql-request";
import type { RequestMiddleware, ResponseMiddleware } from "graphql-request";

const requestMiddleware: RequestMiddleware = async (request) => {
  if (request.operationName?.toLowerCase() === "Fiat24Countries".toLowerCase())
    return request;

  let authToken = JSON.parse(
    localStorage.getItem(AUTH_TOKEN_STORAGE_KEY) || "null"
  );

  if (!authToken) return request;

  if (authToken.startsWith('"') && authToken.endsWith('"'))
    authToken = authToken.slice(1, -1);

  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: `Bearer ${authToken}`,
    },
  };
};

const logUserOut = async () => {
  for (const item of localStorageItemsMaintained) {
    localStorage.removeItem(item);
  }

  window.location.href = "/login";
};

const responseMiddleware: ResponseMiddleware = async (response) => {
  if (response instanceof Error) {
    if (response instanceof ClientError) {
      if (response.response.status === 401) {
        await logUserOut();
      }
    }

    return response;
  }

  if (response.status === 401) {
    await logUserOut();
  }

  return response;
};

export const graphqlClient = new GraphQLClient(
  import.meta.env.VITE_APP_API_URL,
  {
    headers: {
      "Content-Type": "application/json",
    },
    requestMiddleware,
    responseMiddleware,
  }
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: "all",
      gcTime: 1000 * 60 * 60 * 24,
    },
    mutations: {
      retry: false,
    },
  },
});

export type QueryClientType = typeof queryClient;
