import type { SVGProps } from "react";

export default function HomeSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <title>Home</title>
      <path d="M9.99991 13.9334C11.6281 13.9334 12.9481 12.6135 12.9481 10.9853C12.9481 9.35704 11.6281 8.03711 9.99991 8.03711C8.37169 8.03711 7.05176 9.35704 7.05176 10.9853C7.05176 12.6135 8.37169 13.9334 9.99991 13.9334Z" />
      <path d="M18.1479 14.3554C18.1479 15.25 17.793 16.108 17.1612 16.7413C16.5293 17.3745 15.672 17.7313 14.7775 17.7332H5.22193C4.32737 17.7313 3.47013 17.3745 2.83827 16.7413C2.20642 16.108 1.85156 15.25 1.85156 14.3554V10.4147C1.85374 9.52107 2.20766 8.66422 2.83675 8.02952L7.61453 3.25915C7.92757 2.94559 8.29936 2.69683 8.70864 2.52711C9.11791 2.35738 9.55664 2.27002 9.99971 2.27002C10.4428 2.27002 10.8815 2.35738 11.2908 2.52711C11.7001 2.69683 12.0719 2.94559 12.3849 3.25915L17.1627 8.02952C17.7918 8.66422 18.1457 9.52107 18.1479 10.4147V14.3554Z" />
    </svg>
  );
}
