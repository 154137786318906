import type { SVGProps } from "react";

const Logout = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <title>Logout Icon</title>
      <path
        d="M15.324 2.66699C18.634 2.66699 21.3337 5.32033 21.3337 8.58699V14.9737H13.1941C12.6108 14.9737 12.1496 15.427 12.1496 16.0003C12.1496 16.5603 12.6108 17.027 13.1941 17.027H21.3337V23.4003C21.3337 26.667 18.634 29.3337 15.2968 29.3337H8.69025C5.3666 29.3337 2.66699 26.6803 2.66699 23.4137V8.60033C2.66699 5.32033 5.38017 2.66699 8.70381 2.66699H15.324ZM24.7206 11.4006C25.1206 10.9873 25.7739 10.9873 26.1739 11.3873L30.0673 15.2673C30.2673 15.4673 30.3739 15.7206 30.3739 16.0006C30.3739 16.2673 30.2673 16.5339 30.0673 16.7206L26.1739 20.6006C25.9739 20.8006 25.7073 20.9073 25.4539 20.9073C25.1873 20.9073 24.9206 20.8006 24.7206 20.6006C24.3206 20.2006 24.3206 19.5473 24.7206 19.1473L26.8539 17.0273H21.3339V14.9739H26.8539L24.7206 12.8539C24.3206 12.4539 24.3206 11.8006 24.7206 11.4006Z"
        fill="#FF4842"
      />
    </svg>
  );
};

export default Logout;
