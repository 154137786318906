import type { QueryClientType } from "@/lib/query-client";
import type { AuthContextProps } from "@/providers/AuthProvider/data";
import {
  Outlet,
  ScrollRestoration,
  createRootRouteWithContext,
} from "@tanstack/react-router";
import { Suspense } from "react";

interface CustomRouterContext {
  auth: AuthContextProps;
  queryClient: QueryClientType;
}

export const Route = createRootRouteWithContext<CustomRouterContext>()({
  component: RootRoute,
});

function RootRoute() {
  return (
    <>
      <Suspense fallback={<div>Loading....</div>}>
        <ScrollRestoration />
        <Outlet />
      </Suspense>
    </>
  );
}
