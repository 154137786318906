import type {
  GenerateRandomTokenIdQuery,
  GetUserQuery,
  RecheckNftTokenIdQuery,
  RewardListInput,
} from "@/graphql/graphql";
import { graphqlClient } from "@/lib/query-client";
import {
  cashbackListDocument,
  checkIfUserHoldsNFT,
  fetchCashbackMetadata,
  fetchRewardsMetadata,
  generateRandomTokenIdDocument,
  getCardInfoDocument,
  getCardLimitsDocument,
  getContactsDocument,
  getDepositBankDetailsDocument,
  getUploadUrlDocument,
  getUserDocument,
  recheckNFTTokenIdDocument,
  rewardsListDocument,
} from "@/utils/documents";
import type { UndefinedInitialDataOptions } from "@tanstack/react-query";
import { queryOptions } from "@tanstack/react-query";

export const getGenerateRandomTokenOptions = (
  options: Omit<
    UndefinedInitialDataOptions<GenerateRandomTokenIdQuery>,
    "queryFn" | "queryKey"
  >
) =>
  queryOptions({
    queryKey: ["generateRandomTokenID"],
    queryFn: () => graphqlClient.request(generateRandomTokenIdDocument),
    ...options,
  });

export const getUserQueryOptions = (
  options: Omit<UndefinedInitialDataOptions<GetUserQuery>, "queryFn">
) => {
  return queryOptions({
    queryFn: ({ queryKey, signal }) =>
      graphqlClient.request({
        document: getUserDocument,
        variables: {
          walletAddress: (queryKey[1] as unknown as string).toLowerCase(),
        },
        signal,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
};

export const getRecheckTokenIDOptions = (
  tokenId: number,
  options?: Omit<
    UndefinedInitialDataOptions<RecheckNftTokenIdQuery>,
    "queryFn" | "queryKey"
  >
) => {
  return queryOptions({
    queryKey: ["recheckNFTTokenID", tokenId],
    queryFn: ({ queryKey, signal }) =>
      graphqlClient.request({
        document: recheckNFTTokenIdDocument,
        variables: { token: queryKey[1] as unknown as number },
        signal,
      }),
    ...options,
  });
};

export const checkIfUserHoldsNFTOptions = (address: `0x${string}`) => {
  return queryOptions({
    queryKey: ["checkUserHoldsNFT", address.toLowerCase()],
    queryFn: ({ signal }) =>
      graphqlClient.request({
        document: checkIfUserHoldsNFT,
        variables: { walletAddress: address },
        signal,
      }),
  });
};

export const getRewardsMetadataOptions = (abortSignal?: AbortSignal) =>
  queryOptions({
    queryKey: ["rewardsMeta"],
    queryFn: ({ signal }) =>
      graphqlClient.request({
        document: fetchRewardsMetadata,
        signal: abortSignal ?? signal,
      }),
  });

export const rewardsListOptions = (input: RewardListInput) =>
  queryOptions({
    queryKey: ["rewards"],
    queryFn: ({ signal }) =>
      graphqlClient.request({
        document: rewardsListDocument,
        variables: { input },
        signal,
      }),
  });

export const getCashbackMetadataOptions = (abortSignal?: AbortSignal) =>
  queryOptions({
    queryKey: ["cashbackMeta"],
    queryFn: ({ signal }) =>
      graphqlClient.request({
        document: fetchCashbackMetadata,
        signal: abortSignal ?? signal,
      }),
  });

export const cashbackListOptions = (input: RewardListInput) =>
  queryOptions({
    queryKey: ["cashback"],
    queryFn: ({ signal }) =>
      graphqlClient.request({
        document: cashbackListDocument,
        variables: { input },
        signal,
      }),
  });

export const getDepositBankDetailsOptions = (abortSignal?: AbortSignal) =>
  queryOptions({
    queryKey: ["depositBankDetails"],
    queryFn: ({ signal }) =>
      graphqlClient.request({
        document: getDepositBankDetailsDocument,
        signal: abortSignal ?? signal,
      }),
    staleTime: Number.POSITIVE_INFINITY,
  });

export const getContactsQueryOptions = (abortSignal?: AbortSignal) =>
  queryOptions({
    queryKey: ["contacts"],
    queryFn: ({ signal }) =>
      graphqlClient.request({
        document: getContactsDocument,
        signal: abortSignal ?? signal,
      }),
    staleTime: 1000 * 60 * 20, // 20 minutes
  });

export const getCardInfoQueryOptions = (abortSignal?: AbortSignal) =>
  queryOptions({
    queryKey: ["cardInfo"],
    queryFn: ({ signal }) =>
      graphqlClient.request({
        document: getCardInfoDocument,
        signal: abortSignal ?? signal,
      }),
    staleTime: 1000 * 60 * 20, // 20 minutes
    retryOnMount: false,
  });

export const getCardLimitsQueryOptions = (abortSignal?: AbortSignal) =>
  queryOptions({
    queryKey: ["getCardLimits"],
    queryFn: ({ signal }) =>
      graphqlClient.request({
        document: getCardLimitsDocument,
        signal: abortSignal ?? signal,
      }),
    staleTime: 1000 * 60 * 20, // 20 minutes
  });

export const getCoinPricesInCurrencyOptions = ({
  vs_currency,
  id,
  abortSignal,
}: {
  vs_currency: string;
  id: string;
  abortSignal?: AbortSignal;
}) =>
  queryOptions({
    queryKey: ["coinPricesInUSD", vs_currency, id],
    queryFn: ({ signal }) =>
      fetch(
        `https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=${vs_currency}`,
        {
          signal: signal ?? abortSignal,
        }
      ).then((res) => res.json()),
    staleTime: 1000 * 60 * 1, // 1 minute
    retry: true,
  });

export const getUploadUrlQueryOptions = ({
  imageType,
  abortSignal,
}: {
  imageType: string;
  abortSignal?: AbortSignal;
}) =>
  queryOptions({
    queryKey: ["getUploadUrl", imageType],
    queryFn: ({ signal }) =>
      graphqlClient.request({
        document: getUploadUrlDocument,
        variables: { input: { type: imageType } },
        signal: abortSignal ?? signal,
      }),
    staleTime: 0,
    retry: true,
  });
