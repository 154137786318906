import { localeNameShortForms } from "@/data/locale-i18n";
import { cn } from "@/lib/utils";
import { useLang } from "@/providers/LangProvider";
import { GlobeAltIcon } from "@heroicons/react/24/solid";
import { ChevronDown } from "lucide-react";
import { Button } from "./ui/button";

export default function LocaleSelectTrigger({
  className,
}: { className?: string }) {
  const { locale, openLocaleSelector } = useLang();

  return (
    <Button
      variant="outline"
      onClick={openLocaleSelector}
      className={cn(
        "z-20 items-center rounded-xl p-2 text-[#1D1A1A] transition-colors dark:text-white",
        className
      )}
    >
      <GlobeAltIcon
        width="20"
        height="20"
        className="h-5 w-5 dark:text-white"
      />

      {localeNameShortForms[locale]}

      <ChevronDown stroke="#4B4B4B" width="20" />
    </Button>
  );
}
