import { queryKeys } from "@/constants/query-keys";
import AuthenticatedRoutesLayout from "@/layouts/AuthenticatedRoutesLayout";
import { getUserQueryOptions } from "@/utils/query-options";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/_authenticated")({
  validateSearch: z.object({
    cardType: z.string().optional(),
  }),

  async beforeLoad({ context: { auth, queryClient }, location }) {
    if (!auth.address || !auth.hasAuthToken) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }

    try {
      const userData = await queryClient.fetchQuery(
        getUserQueryOptions({
          queryKey: queryKeys.getUserQueryKey(auth.address),
        })
      );

      if (!userData.getUser?.nft?.isMinted) {
        throw redirect({
          to: "/login",
          search: {
            redirect: location.href,
          },
        });
      }
    } catch (error) {}
  },
  component: AuthenticatedRoutesLayout,
});
