import type { SVGProps } from "react";

const Copy = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <title>Copy</title>
      <path
        d="M5.5 5.61688V14.4375C5.49987 15.3102 5.8317 16.1504 6.42818 16.7874C7.02466 17.4245 7.84114 17.8109 8.712 17.8681L8.9375 17.875H15.0067C14.8646 18.2771 14.6013 18.6252 14.2531 18.8714C13.9049 19.1177 13.489 19.2499 13.0625 19.25H8.25C7.15598 19.25 6.10677 18.8154 5.33318 18.0418C4.5596 17.2682 4.125 16.219 4.125 15.125V7.5625C4.12478 7.1358 4.2569 6.71954 4.50316 6.37108C4.74943 6.02261 5.09771 5.75911 5.5 5.61688ZM15.8125 2.75C16.3595 2.75 16.8841 2.9673 17.2709 3.35409C17.6577 3.74089 17.875 4.26549 17.875 4.8125V14.4375C17.875 14.9845 17.6577 15.5091 17.2709 15.8959C16.8841 16.2827 16.3595 16.5 15.8125 16.5H8.9375C8.39049 16.5 7.86589 16.2827 7.47909 15.8959C7.0923 15.5091 6.875 14.9845 6.875 14.4375V4.8125C6.875 4.26549 7.0923 3.74089 7.47909 3.35409C7.86589 2.9673 8.39049 2.75 8.9375 2.75H15.8125Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Copy;
