import type { SVGProps } from "react";

const DebitCardSvg = ({
  pathFillColor,
  ...props
}: SVGProps<SVGSVGElement> & { pathFillColor?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      {...props}
    >
      <title>Debit Card</title>
      <path
        d="M27.0938 6.375H6.90625C4.85244 6.375 3.1875 8.03994 3.1875 10.0938V23.9062C3.1875 25.9601 4.85244 27.625 6.90625 27.625H27.0938C29.1476 27.625 30.8125 25.9601 30.8125 23.9062V10.0938C30.8125 8.03994 29.1476 6.375 27.0938 6.375Z"
        stroke={pathFillColor ?? "#3D3D3D"}
        strokeWidth="2.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.1875 12.75H30.8125M8.5 19.9219H11.6875V21.25H8.5V19.9219Z"
        stroke={pathFillColor ?? "#3D3D3D"}
        strokeWidth="3.98438"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DebitCardSvg;
