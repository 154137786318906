import { queryKeys } from "@/constants/query-keys";
import { getUserQueryOptions } from "@/utils/query-options";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/_authdependant")({
  validateSearch: z.object({
    referral: z.string().optional(),
  }),
  async beforeLoad({ context: { auth, queryClient } }) {
    if (!auth.address || !auth.hasAuthToken) return;

    try {
      const userData = await queryClient.fetchQuery(
        getUserQueryOptions({
          queryKey: queryKeys.getUserQueryKey(auth.address),
        })
      );
      if (userData.getUser?.nft?.isMinted) {
        throw redirect({
          to: "",
        });
      }
    } catch (error) {}
  },
});
