import type { GetUserQuery } from "@/graphql/graphql";
import { graphqlClient } from "@/lib/query-client";
import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { getUserDocument } from "../../utils/documents";

export function useGetUser(
  options: Omit<UseQueryOptions<GetUserQuery>, "queryFn">
) {
  return useQuery({
    queryFn: ({ queryKey, signal }) =>
      graphqlClient.request({
        document: getUserDocument,
        variables: { walletAddress: queryKey[1] as unknown as string },
        signal,
      }),
    staleTime: 1000 * 60 * 60,
    ...options,
  });
}
