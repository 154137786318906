import ARB from "@/assets/icons/svg/ARB.svg";
import CHF from "@/assets/icons/svg/CHF.svg";
import CNH from "@/assets/icons/svg/CNH.svg";
import EUR from "@/assets/icons/svg/EUR.svg";
import USA from "@/assets/icons/svg/USAflag.svg";
import tether from "@/assets/icons/svg/tether.svg";
import eth from "@/assets/images/ETH.png";
import type { Fiat24CurrencyType, PayableCurrencyType } from "@/types/general";

export const fiat24CurrencyToIconMap = new Map<Fiat24CurrencyType, string>([
  ["eur", EUR],
  ["usd", USA],
  ["chf", CHF],
  ["cnh", CNH],
]);

export const fiat24CurrencyToSymbolMap = new Map<Fiat24CurrencyType, string>([
  ["eur", "€"],
  ["usd", "$"],
  ["chf", "₣"],
  ["cnh", "¥"],
]);

export const fiat24Currencies = ["eur", "usd", "chf", "cnh"] as const;

export const displayFiat24Currencies = new Map<Fiat24CurrencyType, string>([
  ["eur", "eur"],
  ["usd", "usd"],
  ["chf", "chf"],
  ["cnh", "rmb"],
]);

export const fiat24CurrencyToAddressMap = new Map<
  Fiat24CurrencyType,
  `0x${string}`
>([
  ["eur", import.meta.env.VITE_FIAT24_EUR24_ADDRESS],
  ["usd", import.meta.env.VITE_FIAT24_USD24_ADDRESS],
  ["chf", import.meta.env.VITE_FIAT24_CHF24_ADDRESS],
  ["cnh", import.meta.env.VITE_FIAT24_CNH24_ADDRESS],
]);

export const exchangeRateFiat24CurrencyPairs = new Map([
  ["usd_chf", "usdToChfExchangeRate"],
  ["usd_cnh", "usdToCnhExchangeRate"],
  ["usd_eur", "usdToEurExchangeRate"],

  ["eur_usd", "eurToUsdExchangeRate"],
  ["eur_chf", "eurToChfExchangeRate"],
  ["eur_cnh", "eurToCnhExchangeRate"],

  ["chf_usd", "chfToUsdExchangeRate"],
  ["chf_eur", "chfToEurExchangeRate"],
  ["chf_cnh", "chfToCnhExchangeRate"],

  ["cnh_usd", "cnhToUsdExchangeRate"],
  ["cnh_eur", "cnhToEurExchangeRate"],
  ["cnh_chf", "cnhToChfExchangeRate"],
]);

export const payableCurrencies = ["eth", "arb", "usdt"] as const;

export const payableCurrencyToAddresMap = new Map<
  PayableCurrencyType,
  `0x${string}` | undefined
>([
  ["eth", undefined],
  ["arb", import.meta.env.VITE_ARB_TOKEN_ADDRESS],
  ["usdt", import.meta.env.VITE_USDT_TOKEN_ADDRESS],
]);

export const payableCurrenyToDecimalsMap = new Map<PayableCurrencyType, number>(
  [
    ["eth", 18],
    ["arb", 18],
    ["usdt", 6],
  ]
);

export const payabableCurrencyToIconMap = new Map<PayableCurrencyType, string>([
  ["arb", ARB],
  ["eth", eth],
  ["usdt", tether],
]);

export const payableCurrencyToNameMap = new Map<PayableCurrencyType, string>([
  ["arb", "arbitrum"],
  ["eth", "ethereum"],
  ["usdt", "tether"],
]);
