import { useMatches } from "@tanstack/react-router";
import { useMemo } from "react";

export default function useMatchRoutes() {
  const {
    isDashboardRoute,
    isRewardsRoute,
    isSettingsRoute,
    isSettingsRoutes,
    isCashbackRoute,
  } = useMatches({
    select(matches) {
      return {
        isSettingsRoute: matches.some(
          (match) => match.pathname === "/my/settings/"
        ),
        isSettingsRoutes: matches.some(
          (match) =>
            match.pathname.includes("/my/settings/") &&
            match.pathname !== "/my/settings/"
        ),
        isDashboardRoute: matches.some(
          (match) => match.pathname === "/my/dashboard"
        ),
        isRewardsRoute: matches.some((match) => match.pathname === "/rewards"),
        isCashbackRoute: matches.some(
          (match) => match.pathname === "/cashback"
        ),
      };
    },
  });

  return {
    isDashboardRoute: useMemo(() => isDashboardRoute, [isDashboardRoute]),
    isRewardsRoute: useMemo(() => isRewardsRoute, [isRewardsRoute]),
    isSettingsRoute: useMemo(() => isSettingsRoute, [isSettingsRoute]),
    isSettingsRoutes: useMemo(() => isSettingsRoutes, [isSettingsRoutes]),
    isCashbackRoute: useMemo(() => isCashbackRoute, [isCashbackRoute]),
  };
}
