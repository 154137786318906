import { graphqlClient } from "@/lib/query-client";
import { rewardsListDocument } from "@/utils/documents";
import { getRewardsMetadataOptions } from "@/utils/query-options";
import { createFileRoute } from "@tanstack/react-router";
import PendingComponent from "./-PendingComponent";

export const Route = createFileRoute("/_authenticated/rewards")({
  loader: async ({ context: { queryClient }, abortController }) => {
    await queryClient.prefetchInfiniteQuery({
      queryKey: ["rewards"],
      queryFn: ({ signal, pageParam }) =>
        graphqlClient.request({
          document: rewardsListDocument,
          variables: {
            input: { limit: pageParam.limit, cursor: pageParam.cursor },
          },
          signal,
        }),
      initialPageParam: { limit: 5, cursor: "" },
    });
    return queryClient.ensureQueryData(
      getRewardsMetadataOptions(abortController.signal)
    );
  },
  onError: (error) => {
    console.error("Error fetching rewards metadata", error);
  },
  pendingComponent: PendingComponent,
});
