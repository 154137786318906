import { graphqlClient } from "@/lib/query-client";
import { cashbackListDocument } from "@/utils/documents";
import { getCashbackMetadataOptions } from "@/utils/query-options";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/cashback")({
  loader: async ({ context: { queryClient }, abortController }) => {
    await queryClient.prefetchInfiniteQuery({
      queryKey: ["cashback"],
      queryFn: ({ signal, pageParam }) =>
        graphqlClient.request({
          document: cashbackListDocument,
          variables: {
            input: { limit: pageParam.limit, cursor: pageParam.cursor },
          },
          signal,
        }),
      initialPageParam: { limit: 5, cursor: "" },
    });
    return queryClient.ensureQueryData(
      getCashbackMetadataOptions(abortController.signal)
    );
  },
  onError: (error) => {
    console.error("Error fetching rewards metadata", error);
  },
});
