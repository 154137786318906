import LanguageSelectorDialog from "@/components/LanguageSelectorDialog";
import { LANGUAGE_KEY } from "@/constants/storage-constants";
import { availableLocales } from "@/data/locale-i18n";
import type { AvailableLocales } from "@/types/general";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IntlProvider } from "react-intl";
import { useBoolean, useLocalStorage } from "usehooks-ts";

interface LangProviderProps {
  children: React.ReactNode;
}

type LangProviderContextType = {
  locale: AvailableLocales;
  setLocale: (locale: AvailableLocales) => void;
  openLocaleSelector: () => void;
};

const LangProviderContext = createContext<LangProviderContextType>({
  locale: "en",
  setLocale: (_locale: AvailableLocales) => {},
  openLocaleSelector: () => {},
});

export default function LangProvider({ children }: LangProviderProps) {
  const [locale, setLocale] = useState<AvailableLocales>("en");
  const [messages, setMessages] = useState({});

  const [, setLocaleInStorage] = useLocalStorage(LANGUAGE_KEY, "en");

  const {
    value: showLanguageSelectorDialog,
    setTrue: openLanguageSelectorDialog,
    setFalse: closeLanguageSelectorDialog,
  } = useBoolean(false);

  useEffect(() => {
    let localeInStorage = localStorage.getItem(LANGUAGE_KEY);

    if (localeInStorage) {
      if (localeInStorage.startsWith('"') && localeInStorage.endsWith('"'))
        localeInStorage = localeInStorage.slice(1, -1);

      if (availableLocales.includes(localeInStorage as AvailableLocales)) {
        setLocale(localeInStorage as AvailableLocales);
      }
    }
  }, []);

  useEffect(() => {
    import(`./lang/${locale}.json`).then((messages) => {
      setMessages(messages.default);
    });
  }, [locale]);

  const updateLocale = useCallback(
    (locale: AvailableLocales) => {
      setLocaleInStorage(locale);
      setLocale(locale);
    },
    [setLocaleInStorage]
  );

  return (
    <LangProviderContext.Provider
      value={{
        locale,
        setLocale: updateLocale,
        openLocaleSelector: openLanguageSelectorDialog,
      }}
    >
      <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
        {children}
        <LanguageSelectorDialog
          isOpen={showLanguageSelectorDialog}
          onClose={closeLanguageSelectorDialog}
        />
      </IntlProvider>
    </LangProviderContext.Provider>
  );
}

export const useLang = () => {
  const context = useContext(LangProviderContext);
  if (context === undefined) {
    throw new Error("useLang must be used within a LangProvider");
  }
  return context;
};
