import type { GetUserQuery } from "@/graphql/graphql";

export const initialAuthData = {
  user: null,
  fetchingUser: false,
  hasAuthToken: false,
  address: undefined,
  logout: async () => {},
};

export type AuthContextProps = {
  fetchingUser: boolean;
  hasAuthToken: boolean;
  user: GetUserQuery["getUser"];
  address?: `0x${string}`;
  logout: () => Promise<void>;
};
