import { queryKeys } from "@/constants/query-keys";
import useCountries, { useCountriesQueryKey } from "@/hooks/api/useCountries";
import { useGetUser } from "@/hooks/api/useGetUser";
import useJWTToken from "@/hooks/useToken";
import { localStorageItemsMaintained } from "@/utils/utils";
import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { createContext, useCallback, useContext } from "react";
import type { ReactNode } from "react";
import { useAccount, useDisconnect } from "wagmi";
import type { AuthContextProps } from "./data";
import { initialAuthData } from "./data";

const AuthContext = createContext<AuthContextProps>(initialAuthData);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();
  const [authToken] = useJWTToken();

  useCountries();

  const { address } = useAccount();
  const { disconnectAsync } = useDisconnect();

  const { data: userData, isLoading: fetchingUser } = useGetUser({
    queryKey: queryKeys.getUserQueryKey(address),
    enabled: !!address && !!authToken,
    staleTime: 1000 * 60 * 60,
  });

  const logout = useCallback(async () => {
    await disconnectAsync();
    queryClient.resetQueries({
      predicate(query) {
        return query.queryKey[0] !== useCountriesQueryKey.at(0);
      },
    });
    queryClient.removeQueries({
      predicate(query) {
        return query.queryKey[0] !== useCountriesQueryKey.at(0);
      },
    });
    for (const item of localStorageItemsMaintained) {
      localStorage.removeItem(item);
    }

    queryClient.cancelQueries();
    Sentry.setUser(null);
    Sentry.endSession();
  }, [queryClient, disconnectAsync]);

  return (
    <AuthContext.Provider
      value={{
        user: userData?.getUser,
        fetchingUser,
        hasAuthToken: !!authToken,
        address,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
