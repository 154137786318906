import {
  displayFiat24Currencies,
  fiat24Currencies,
  fiat24CurrencyToIconMap,
} from "@/constants/general";
import useMatchRoutes from "@/hooks/useMatchRoutes";
import useGetAllBalances from "@/hooks/web3/useGetAllBalances";
import { cn } from "@/lib/utils";
import { useAuth } from "@/providers/AuthProvider";
import { useTheme } from "@/providers/ThemeProvider";
import type { Fiat24CurrencyType } from "@/types/general";
import { useNavigate } from "@tanstack/react-router";
import { X } from "lucide-react";
import { useCallback } from "react";
import DotLoader from "./DotLoader";
import { Button } from "./ui/button";
import { Dialog, DialogClose, DialogContent, DialogHeader } from "./ui/dialog";

interface CurrencySelectorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCardType: Fiat24CurrencyType;
}

export default function CurrencySelectorDialog({
  isOpen,
  onClose,
  selectedCardType,
}: CurrencySelectorDialogProps) {
  const { user } = useAuth();

  const navigate = useNavigate();
  const { isDashboardRoute } = useMatchRoutes();

  const { data: balances, isPending } = useGetAllBalances(
    user?.walletAddress as `0x${string}`
  );

  const handleSetCardType = useCallback(
    (cardType: string) => async () => {
      if (isDashboardRoute) {
        await navigate({ search: { cardType: cardType } });
      } else {
        await navigate({
          to: "/my/dashboard",
          search: { cardType: cardType.toLowerCase() },
        });
      }

      onClose();
    },
    [navigate, isDashboardRoute, onClose]
  );
  const { theme } = useTheme();
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-lg rounded-3xl bg-white shadow-[1px_10px_11px_0px_rgba(38,38,38,0.06)] dark:bg-[#2B1F2E]">
        <DialogHeader className="relative">
          <p className="text-center font-medium font-poppins text-base text-black transition-colors dark:text-white">
            Choose Currency
          </p>
          <DialogClose className="absolute top-0 right-0">
            <X stroke={theme === "dark" ? "#FFFFFF" : "#6236FF"} />
          </DialogClose>
        </DialogHeader>

        <ul className="flex w-full flex-col">
          {fiat24Currencies.map((availablecurrency) => (
            <li key={availablecurrency} className="w-full">
              <Button
                variant="primary"
                size="icon"
                className={cn(
                  "w-full justify-between rounded-xl bg-transparent px-6 py-3 dark:bg-transparent hover:bg-transparent",

                  {
                    "bg-[#6332CB] hover:bg-[#6332CB] dark:bg-[#6332CB]":
                      selectedCardType === availablecurrency,
                  }
                )}
                onClick={handleSetCardType(availablecurrency)}
              >
                <img
                  src={fiat24CurrencyToIconMap.get(availablecurrency)}
                  alt={availablecurrency}
                  width="32"
                  height="32"
                  className="h-8 w-8 rounded-full"
                />
                {isPending ? (
                  <DotLoader
                    className="!w-5"
                    dotColor={
                      selectedCardType === availablecurrency ? "#fff" : "#000"
                    }
                  />
                ) : (
                  <p
                    className={cn(
                      "font-poppins text-base text-black transition-colors dark:text-white",
                      {
                        "font-medium text-white":
                          selectedCardType === availablecurrency,
                      }
                    )}
                  >
                    {balances.get(availablecurrency)?.toFixed(2) ?? "0"}{" "}
                    {displayFiat24Currencies
                      .get(availablecurrency)!
                      .toUpperCase()}
                  </p>
                )}
              </Button>
            </li>
          ))}
        </ul>
      </DialogContent>
    </Dialog>
  );
}
