import {
  displayFiat24Currencies,
  fiat24Currencies,
  fiat24CurrencyToIconMap,
} from "@/constants/general";
import useMatchRoutes from "@/hooks/useMatchRoutes";
import useGetAllBalances from "@/hooks/web3/useGetAllBalances";
import { cn } from "@/lib/utils";
import { useAuth } from "@/providers/AuthProvider";
import type { Fiat24CurrencyType } from "@/types/general";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useCallback, useMemo } from "react";
import DotLoader from "./DotLoader";
import { Button } from "./ui/button";

export default function CurrencySideNavSection() {
  const { user } = useAuth();
  const { cardType: cardTypeSeach } = useSearch({
    from: "/_authenticated",
  });

  const navigate = useNavigate();

  const { isDashboardRoute } = useMatchRoutes();

  const { data, isPending } = useGetAllBalances(
    user?.walletAddress as `0x${string}`
  );

  const handleSetCardType = useCallback(
    (cardType: string) => () => {
      if (isDashboardRoute) {
        navigate({ search: { cardType: cardType } });
      } else {
        navigate({
          to: "/my/dashboard",
          search: { cardType: cardType.toLowerCase() },
        });
      }
    },
    [navigate, isDashboardRoute]
  );

  const selectedCardType = useMemo(
    () => (cardTypeSeach ?? fiat24Currencies[0]) as Fiat24CurrencyType,
    [cardTypeSeach]
  );

  return (
    <ul className="mb-7 flex w-full flex-col items-center">
      {fiat24Currencies.map((availablecurrency) => (
        <li
          key={availablecurrency}
          className="group-data-[expanded=true]:w-full"
        >
          <Button
            variant="primary"
            size="icon"
            className={cn(
              "flex-col items-center rounded-xl bg-transparent px-6 pt-3 pb-1 hover:bg-transparent",
              "group-data-[expanded=true]:w-full group-data-[expanded=true]:flex-row group-data-[expanded=true]:justify-between dark:bg-transparent group-data-[expanded=true]:py-5 group-data-[expanded=true]:pr-8 group-data-[expanded=true]:pl-5",
              {
                "bg-[#6332CB] hover:bg-[#6332CB] dark:bg-[#6332CB]":
                  selectedCardType === availablecurrency,
              }
            )}
            onClick={handleSetCardType(availablecurrency)}
          >
            <img
              src={fiat24CurrencyToIconMap.get(availablecurrency)}
              alt={availablecurrency}
              width="32"
              height="32"
              className="h-8 w-8 rounded-full"
            />
            {isPending ? (
              <DotLoader
                className="!w-5"
                dotColor={
                  selectedCardType === availablecurrency ? "#fff" : "#000"
                }
              />
            ) : (
              <p
                className={cn(
                  "font-poppins text-base text-black transition-colors dark:text-white",
                  {
                    "font-medium text-white":
                      selectedCardType === availablecurrency,
                  }
                )}
              >
                {data.get(availablecurrency)?.toFixed(2) ?? "0"}{" "}
                {displayFiat24Currencies.get(availablecurrency)!.toUpperCase()}
              </p>
            )}
          </Button>
        </li>
      ))}
    </ul>
  );
}
