import type { SVGProps } from "react";

export function ContactsSvg({
  pathFillColor,
  ...props
}: SVGProps<SVGSVGElement> & { pathFillColor?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      {...props}
    >
      <title>Contacts</title>
      <path
        d="M16.9107 5.34045C13.9826 5.34045 11.6089 8.11174 11.6089 11.5303C11.6089 13.9012 12.7507 15.9603 14.4272 17L12.483 17.901L7.30941 20.3005C6.80961 20.5504 6.56016 20.9742 6.56016 21.574V27.2719C6.60127 27.9843 7.02871 28.6482 7.72171 28.6595H26.1276C26.9188 28.5907 27.319 27.9534 27.3268 27.2719V21.574C27.3268 20.9742 27.0774 20.5504 26.5776 20.3005L21.5913 17.901L19.519 16.9192C21.1268 15.8561 22.2124 13.8416 22.2124 11.5303C22.2124 8.11174 19.8387 5.34045 16.9107 5.34045ZM8.35899 7.51713C7.09892 7.56499 6.10028 8.11024 5.34157 8.97902C4.50234 10.025 4.0932 11.2669 4.0853 12.5024C4.13684 14.3293 4.95425 16.0587 6.40945 16.9634L0.599618 19.6632C0.19975 19.8131 0 20.163 0 20.7128V25.2857C0.0312233 25.8922 0.34612 26.4019 0.936558 26.4107H4.79794V21.574C4.86183 20.2809 5.46907 19.2357 6.56016 18.7256L10.4205 16.8891C10.7204 16.7142 11.0079 16.4764 11.2828 16.1765C9.6911 13.7207 9.47008 10.7792 10.4959 8.19212C9.82869 7.78381 9.07891 7.52141 8.35899 7.51713ZM25.6023 7.51713C24.7782 7.53439 24.0167 7.83784 23.3912 8.26638C24.389 10.8769 24.1137 13.8202 22.6408 16.1011C22.9657 16.476 23.3039 16.7635 23.6538 16.9634L27.3645 18.7256C28.4949 19.3456 29.0778 20.4 29.089 21.574V26.4107H33.0624C33.7158 26.3543 33.9943 25.8331 34 25.2858V20.7128C34 20.213 33.8003 19.8632 33.4004 19.6632L27.6648 16.9257C29.1523 15.829 29.8992 14.1853 29.9136 12.5024C29.8741 11.1704 29.4672 9.93357 28.6574 8.97902C27.8111 8.06105 26.7616 7.52685 25.6023 7.51713Z"
        fill={pathFillColor ?? "#3D3D3D"}
      />
    </svg>
  );
}
