import { UserDefaultImage } from "@/components/UserDefaultImage";
import { Skeleton } from "@/components/ui/skeleton";
import { RewardType } from "@/graphql/graphql";
import { graphqlClient } from "@/lib/query-client";
import { cn } from "@/lib/utils";
import { useAuth } from "@/providers/AuthProvider";
import { rewardsListDocument } from "@/utils/documents";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FormattedMessage } from "react-intl";
import { Waypoint } from "react-waypoint";

dayjs.extend(utc);

export function RewardsSkeleton({ className }: RewardsProps) {
  return (
    <section
      className={cn(
        "rounded-2xl bg-white px-0 py-6 pt-0 transition-colors dark:bg-transparent dark:xs:bg-[#2B1F2E] xs:px-10 xs:pt-6",
        className
      )}
    >
      <h1 className="mb-4 font-medium text-[#676767] text-lg transition-colors dark:text-white">
        <FormattedMessage id="my_rewards" defaultMessage="My Rewards" />
      </h1>

      <ul className="list-none">
        {Array.from({ length: 5 }).map((_, index) => (
          <li
            // biome-ignore lint/suspicious/noArrayIndexKey: This is a skeleton loader
            key={index}
            className="flex items-center justify-between gap-x-2 border-b py-4 [border-image-slice:1] [border-image-source:linear-gradient(269.97deg,_rgba(214,_214,_214,_0.121866)_0.04%,_#D6D6D6_54.78%,_rgba(214,_214,_214,_0)_100.01%)] dark:[border-image-source:linear-gradient(to_left,_rgba(255,255,255,0)_0%,_rgba(255,255,255,0.5)_50%,_rgba(255,255,255,0)_100%)]"
          >
            <div className="flex gap-x-5">
              <Skeleton className="h-10 w-10 rounded-full bg-neutral-300 transition-colors dark:bg-neutral-600" />
              <div className="flex flex-col gap-y-1">
                <Skeleton className="h-4 w-64 bg-neutral-300 transition-colors dark:bg-neutral-600" />
                <Skeleton className="h-4 w-64 bg-neutral-300 transition-colors dark:bg-neutral-600" />
              </div>
            </div>
            <Skeleton className="h-4 w-10 bg-neutral-300 dark:bg-neutral-600" />
          </li>
        ))}
      </ul>
    </section>
  );
}

const rewardTypeToColor = new Map([
  [RewardType.Welcome, "#20C257"],
  [RewardType.Claim, "#C22020"],
  [RewardType.Network, "#20C257"],
  [RewardType.Direct, "#20C257"],
]);

interface RewardsProps {
  className?: string;
}

export default function MyRewards({ className }: RewardsProps) {
  const { user } = useAuth();

  const { data, isPending, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useSuspenseInfiniteQuery({
      queryKey: ["rewards"],
      queryFn: ({ signal, pageParam }) =>
        graphqlClient.request({
          document: rewardsListDocument,
          variables: {
            input: { limit: pageParam.limit, cursor: pageParam.cursor },
          },
          signal,
        }),
      initialPageParam: { limit: 5, cursor: "" },
      getNextPageParam: (lastPage) =>
        lastPage.rewards.nextCursor
          ? {
              limit: 5,
              cursor: lastPage.rewards.nextCursor,
            }
          : undefined,
      select(data) {
        const rewards = data.pages.flatMap((page) => page.rewards.rewards);

        return {
          rewards,
        };
      },
      retry: false,
    });

  if (isPending) {
    return <RewardsSkeleton className={className} />;
  }

  const onLoadMore = () => {
    fetchNextPage();
  };

  return (
    <section
      className={cn(
        "rounded-2xl bg-white px-0 py-6 pt-0 transition-colors dark:bg-transparent dark:xs:bg-[#2B1F2E] xs:px-10 xs:pt-6",
        className
      )}
    >
      <h1 className="mb-4 font-medium text-[#676767] text-lg transition-colors dark:text-white">
        <FormattedMessage id="my_rewards" defaultMessage="My Rewards" />
      </h1>

      <ul className="list-none">
        {data.rewards.map((reward) => (
          <li
            key={reward.id}
            className="flex items-center justify-between gap-x-2 border-b py-4 [border-image-slice:1] [border-image-source:linear-gradient(269.97deg,_rgba(214,_214,_214,_0.121866)_0.04%,_#D6D6D6_54.78%,_rgba(214,_214,_214,_0)_100.01%)] dark:[border-image-source:linear-gradient(to_left,_rgba(255,255,255,0)_0%,_rgba(255,255,255,0.5)_50%,_rgba(255,255,255,0)_100%)]"
          >
            <div className="flex gap-x-5">
              <UserDefaultImage
                seed={
                  reward.referrer
                    ? reward.referrer.nft?.tokenId ??
                      reward.referrer.walletAddress
                    : user?.nft?.tokenId ?? 0
                }
                image={reward.referrer ? reward.referrer?.avatar : user?.avatar}
                size={40}
                className="h-10 w-10 rounded-full"
              />

              <div className="flex flex-col gap-y-1">
                <p className="font-poppins font-semibold text-[#1D1A1A] text-base transition-colors dark:text-white">
                  {reward.rewardType === RewardType.Welcome && reward.comment}
                  {reward.rewardType === RewardType.Claim && (
                    <FormattedMessage
                      id="reward_claimed"
                      defaultMessage="Reward claimed"
                    />
                  )}
                  {[RewardType.Network, RewardType.Direct].includes(
                    reward.rewardType
                  ) && (
                    <FormattedMessage
                      id="referral_reward"
                      defaultMessage="Referral reward"
                    />
                  )}
                </p>

                <p className="font-poppins text-[#717171] transition-colors dark:text-white">
                  <span className="capitalize">
                    {reward.referrer?.username?.toLowerCase()}{" "}
                  </span>
                  {dayjs(reward.timestamp).utc().format("YYYY-MM-DD HH:mm:ss")}
                </p>
              </div>
            </div>
            <p
              className="text-right font-medium font-poppins text-base lg:text-2xl sm:text-lg"
              style={{
                color: rewardTypeToColor.get(reward.rewardType),
              }}
            >
              {reward.rewardType === RewardType.Claim && "-"}
              {(reward.rewardType === RewardType.Direct ||
                reward.rewardType === RewardType.Welcome ||
                reward.rewardType === RewardType.Network) &&
                "+"}
              {reward.value} NRK
            </p>
          </li>
        ))}

        {isFetchingNextPage && (
          <ul className="list-none">
            {Array.from({ length: 5 }).map((_, index) => (
              <li
                // biome-ignore lint/suspicious/noArrayIndexKey: This is a skeleton loader
                key={index}
                className="flex items-center justify-between gap-x-2 border-b py-4 [border-image-slice:1] [border-image-source:linear-gradient(269.97deg,_rgba(214,_214,_214,_0.121866)_0.04%,_#D6D6D6_54.78%,_rgba(214,_214,_214,_0)_100.01%)] dark:[border-image-source:linear-gradient(to_left,_rgba(255,255,255,0)_0%,_rgba(255,255,255,0.5)_50%,_rgba(255,255,255,0)_100%)]"
              >
                <div className="flex gap-x-5">
                  <Skeleton className="h-10 w-10 rounded-full bg-neutral-300 dark:bg-neutral-600" />
                  <div className="flex flex-col gap-y-1">
                    <Skeleton className="h-4 w-64 bg-neutral-300 dark:bg-neutral-600" />
                    <Skeleton className="h-4 w-64 bg-neutral-300 dark:bg-neutral-600" />
                  </div>
                </div>
                <Skeleton className="h-4 w-10 bg-neutral-300 dark:bg-neutral-600" />
              </li>
            ))}
          </ul>
        )}

        {!(isPending || isFetchingNextPage) && hasNextPage && (
          <Waypoint onEnter={onLoadMore} />
        )}
      </ul>
    </section>
  );
}
