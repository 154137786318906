import { imageMimeToExtension } from "@/data/general";
import type { QueryClient } from "@tanstack/react-query";
import axios from "axios";
import { getUploadUrlQueryOptions } from "./query-options";

const getImageFileExtension = (mimeType: string) => {
  return imageMimeToExtension[mimeType] || "";
};

export async function uploadUserAvatar(file: File, queryClient: QueryClient) {
  const fileExtension = getImageFileExtension(file.type);
  const { upload: uploadUrl } = await queryClient.fetchQuery(
    getUploadUrlQueryOptions({ imageType: fileExtension })
  );

  try {
    await axios.put(uploadUrl, file, {
      // onUploadProgress: (progressEvent) => {
      //   const percentCompleted = Math.round(
      //     (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
      //   );
      // },
    });
  } catch (error) {
    console.log("Error uploading file", error);
    throw Error("Failed to upload file");
  }
  const urlObj = new URL(uploadUrl);

  return urlObj.origin + urlObj.pathname;
}
