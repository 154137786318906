import { useCallback } from "react";
import { toast } from "sonner";

/**
 * Function to copy text to the clipboard.
 * @param text The text to copy to the clipboard.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the text was copied successfully, or `false` otherwise.
 */
type CopyFn = (text?: string | number) => Promise<boolean>;

/**
 * Custom hook for copying text to the clipboard.
 * @returns {CopyFn} An tuple containing the copied text and a function to copy text to the clipboard.
 * @see [MDN Clipboard API](https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API)
 * @example
 * Usage of useCopyToClipboard hook
 * const copyToClipboard = useCopyToClipboard();
 * const textToCopy = 'Hello, world!';
 *
 * Attempt to copy text to the clipboard
 * copyToClipboard(textToCopy)
 *   .then(success => {
 *     if (success) {
 *       console.log(`Text "${textToCopy}" copied to clipboard successfully.`);
 *     } else {
 *       console.error('Failed to copy text to clipboard.');
 *     }
 *   });
 */
export function useCopyToClipboard(): CopyFn {
  const copy: CopyFn = useCallback(async (text) => {
    if (!text) {
      console.warn("Nothing to copy");
      return false;
    }
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text.toString());
      toast.success("Copied to clipboard");
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      toast.error("Failed to copy to clipboard");
      return false;
    }
  }, []);

  return copy;
}
