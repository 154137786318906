import CopyButton from "@/components/CopyButton";
import { UserDefaultImage } from "@/components/UserDefaultImage";
import { Button } from "@/components/ui/button";
import { fiat24CurrencyToIconMap } from "@/constants/general";
import { useAuth } from "@/providers/AuthProvider";
import type { Fiat24CurrencyType } from "@/types/general";
import { useSearch } from "@tanstack/react-router";
import { ChevronDown } from "lucide-react";
import { useMemo } from "react";
import { useBoolean } from "usehooks-ts";
import CurrencySelectorDialog from "./CurrencySelectorDialog";
import LocaleSelectTrigger from "./LocaleSelectTrigger";

export default function DashboardMobileNav() {
  const { user } = useAuth();
  const token = user?.nft?.tokenId ?? "";

  const {
    value: showCurrencySelectorDialog,
    setTrue: openCurrencySelectorDialog,
    setFalse: closeCurrencySelectorDialog,
  } = useBoolean(false);

  const { cardType: cardTypeSeach } = useSearch({
    from: "/_authenticated",
  });

  const selectedCardType = useMemo(
    () => (cardTypeSeach ?? "eur") as Fiat24CurrencyType,
    [cardTypeSeach]
  );

  return (
    <nav className="flex justify-between px-7 py-3">
      <div className="flex items-center gap-x-2">
        <UserDefaultImage
          seed={token}
          size={36}
          image={user?.avatar}
          className="aspect-square rounded-full"
        />

        <div className="flex flex-col">
          <p className="font-poppins font-semibold text-[#1E1F4B] text-sm leading-normal">
            {user?.username}
          </p>
          <div className="flex items-center gap-x-0.5">
            <p className="font-medium font-poppins text-[#393939] text-sm leading-normal">
              #{token}
            </p>

            <CopyButton message={token} className="text-black" />
          </div>
        </div>
      </div>

      <div className="flex gap-x-2">
        <Button variant="ghost" onClick={openCurrencySelectorDialog}>
          <img
            src={fiat24CurrencyToIconMap.get(selectedCardType)}
            alt={selectedCardType}
            width="24"
            height="24"
            className="h-6 w-6 rounded-full"
          />

          <ChevronDown stroke="#4B4B4B" width="12" />
        </Button>

        <LocaleSelectTrigger />
      </div>

      <CurrencySelectorDialog
        isOpen={showCurrencySelectorDialog}
        onClose={closeCurrencySelectorDialog}
        selectedCardType={selectedCardType}
      />
    </nav>
  );
}
