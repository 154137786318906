import { cn } from "@/lib/utils";

export default function DotLoader({
  className,
  dotColor,
}: { className?: string; dotColor?: string }) {
  return (
    <div
      className={cn("dot-loader", className)}
      style={
        {
          "--dot-color": dotColor || "#fff",
        } as React.CSSProperties
      }
    />
  );
}
