/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation Auth($input: AuthInput!) {\n  auth(input: $input) {\n      token\n      user {\n        id\n        username\n        email\n        referral\n        country\n        walletAddress\n        kyc\n        iban\n        nft {\n          tokenId\n          isMinted\n          feeStatus\n          feeHash\n        }\n      }\n    }\n  }\n": types.AuthDocument,
    "\n  query Fiat24Countries {\n    fiat24Countries {\n      eligibleDomiciles {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      eligibleDomicilesForCards {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      blacklistNationalities {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      nfcPassports {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      nfcNationalIDs {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      lowLimitDomiciles {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n    }\n  }\n": types.Fiat24CountriesDocument,
    "\n  mutation AddUser($user: AddUserInput!) {\n    addUser(user: $user) {\n      id\n      username\n      email\n      referral\n      country\n      walletAddress\n      kyc\n      nft {\n        tokenId\n        isMinted\n        feeStatus\n        feeHash\n      }\n    }\n  }\n": types.AddUserDocument,
    "\n  mutation MintNFT($hash: String!, $tokenId: Int!) {\n    mintNFT(hash: $hash, tokenId: $tokenId)\n  }\n": types.MintNftDocument,
    "\n  mutation ClaimCashBack($amount: Float!) {\n    claimCashBack(amount: $amount)\n  }\n": types.ClaimCashBackDocument,
    "\n  mutation Topup($input: TopupInput!) {\n    topup(input: $input)\n  }\n": types.TopupDocument,
    "\n  mutation ClaimCashback($amount: Float!){\n    claimReward(amount: $amount)\n  }\n": types.ClaimCashbackDocument,
    "\n  query generateRandomTokenID {\n    randomizeNFTTokenID {\n      status\n      message\n      tokenId\n    }\n  }\n": types.GenerateRandomTokenIdDocument,
    "\n  query GetUser($walletAddress: String!) {\n    getUser(walletAddress: $walletAddress) {\n      id\n      username\n      avatar\n      email\n      referral\n      country\n      walletAddress\n      kyc\n      iban\n      nft {\n        tokenId\n        isMinted\n        feeStatus\n        feeHash\n      }\n    }\n  }\n": types.GetUserDocument,
    "\n  query recheckNFTTokenID($token: Int!) {\n    recheckNFTTokenID(tokenId: $token) {\n      status\n      message\n      tokenId\n    }\n  }\n": types.RecheckNftTokenIdDocument,
    "\n  query checkUserHoldsNFT($walletAddress: String!) {\n    checkUserHoldsNFT(walletAddress: $walletAddress)\n  }\n": types.CheckUserHoldsNftDocument,
    "\n  query RewardsMeta {\n    rewardsMeta {\n      directReferenceCount\n      networkReferenceCount\n      withdrawable\n      referralCode\n      minWithdrawalAmount\n    }\n  }\n": types.RewardsMetaDocument,
    "\n  query Rewards($input: RewardListInput!) {\n    rewards(input: $input) {\n      rewards {\n        id\n        referrer {\n          id\n          username\n          avatar\n          email\n          referral\n          country\n          walletAddress\n          kyc\n          nft {\n            tokenId\n            isMinted\n            feeStatus\n            feeHash\n          }\n        }\n        rewardType\n        value\n        comment\n        timestamp\n      }\n      nextCursor\n    }\n  }\n": types.RewardsDocument,
    "\n  query CashbackMeta {\n    cashBackMeta {\n      availableCashBack\n      totalCashBackEarned\n      minClaimAmount\n    }\n  }\n": types.CashbackMetaDocument,
    "\nquery CashBackList($input: CashBackInput!) {\n  cashBackList(input: $input) {\n    cashBack {\n      id\n      type\n      amount\n      hash\n      timestamp\n      comment\n    }\n    nextCursor\n  }\n}\n": types.CashBackListDocument,
    "\n  mutation CreateAuthChallenge {\n    createAuthChallenge {\n      challenge\n      deadline\n    }\n  }\n": types.CreateAuthChallengeDocument,
    "\n  query DepositBanks {\n    depositBanks {\n      CHF {\n        account\n        bank\n        BIC\n        payee\n      }\n      EUR {\n        account\n        bank\n        BIC\n        payee\n        payeeAddress\n        remark\n      }\n    }\n  }\n": types.DepositBanksDocument,
    "\n  query Contacts {\n    contacts {\n      EUR {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n      CHF {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n      GBP {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n      USD {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n    }\n  }\n": types.ContactsDocument,
    "\nquery GetCardInfo {\n  getCardInfo {\n    cardNumber\n    cardHolder\n    cvv\n    expiry\n    securePassword\n    securityInfo {\n      contactlessEnabled\n      withdrawalEnabled\n      internetPurchaseEnabled\n      overallLimitsEnabled\n    }\n    contactLess {\n      used\n      available\n      max\n      dailyUsed\n      dailyAvailable\n      dailyMax\n    }\n    withdrawal {\n      used\n      available\n      max\n      dailyUsed\n      dailyAvailable\n      dailyMax\n    }\n    internetPurchase {\n      used\n      available\n      max\n      dailyUsed\n      dailyAvailable\n      dailyMax\n    }\n    transactionLimits {\n      purchase\n      withdrawal\n      internetPurchase\n      contactless\n    }\n    accountLimits {\n      restartDate\n      used\n      available\n      max\n    }\n  }\n}\n": types.GetCardInfoDocument,
    "\nquery CardLimits {\n  cardLimits {\n    restartDate\n    used\n    available\n    max\n  }\n}\n": types.CardLimitsDocument,
    "\nquery Transactions($input: TransactionListInput!) {\n  transactions(input: $input) {\n    title\n    subtitle\n    amount\n    timestamp\n    image\n    txId\n    from\n    to\n    cashbackAmount\n  }\n}\n": types.TransactionsDocument,
    "\n  query getUploadUrl($input: UploadFileInput!) {\n    upload(input: $input)\n  }\n": types.GetUploadUrlDocument,
    "\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n    }\n  }\n": types.UpdateUserDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Auth($input: AuthInput!) {\n  auth(input: $input) {\n      token\n      user {\n        id\n        username\n        email\n        referral\n        country\n        walletAddress\n        kyc\n        iban\n        nft {\n          tokenId\n          isMinted\n          feeStatus\n          feeHash\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Auth($input: AuthInput!) {\n  auth(input: $input) {\n      token\n      user {\n        id\n        username\n        email\n        referral\n        country\n        walletAddress\n        kyc\n        iban\n        nft {\n          tokenId\n          isMinted\n          feeStatus\n          feeHash\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Fiat24Countries {\n    fiat24Countries {\n      eligibleDomiciles {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      eligibleDomicilesForCards {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      blacklistNationalities {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      nfcPassports {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      nfcNationalIDs {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      lowLimitDomiciles {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n    }\n  }\n"): (typeof documents)["\n  query Fiat24Countries {\n    fiat24Countries {\n      eligibleDomiciles {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      eligibleDomicilesForCards {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      blacklistNationalities {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      nfcPassports {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      nfcNationalIDs {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n      lowLimitDomiciles {\n        name\n        alpha3\n        phoneCode\n        alpha2\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddUser($user: AddUserInput!) {\n    addUser(user: $user) {\n      id\n      username\n      email\n      referral\n      country\n      walletAddress\n      kyc\n      nft {\n        tokenId\n        isMinted\n        feeStatus\n        feeHash\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddUser($user: AddUserInput!) {\n    addUser(user: $user) {\n      id\n      username\n      email\n      referral\n      country\n      walletAddress\n      kyc\n      nft {\n        tokenId\n        isMinted\n        feeStatus\n        feeHash\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MintNFT($hash: String!, $tokenId: Int!) {\n    mintNFT(hash: $hash, tokenId: $tokenId)\n  }\n"): (typeof documents)["\n  mutation MintNFT($hash: String!, $tokenId: Int!) {\n    mintNFT(hash: $hash, tokenId: $tokenId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ClaimCashBack($amount: Float!) {\n    claimCashBack(amount: $amount)\n  }\n"): (typeof documents)["\n  mutation ClaimCashBack($amount: Float!) {\n    claimCashBack(amount: $amount)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Topup($input: TopupInput!) {\n    topup(input: $input)\n  }\n"): (typeof documents)["\n  mutation Topup($input: TopupInput!) {\n    topup(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ClaimCashback($amount: Float!){\n    claimReward(amount: $amount)\n  }\n"): (typeof documents)["\n  mutation ClaimCashback($amount: Float!){\n    claimReward(amount: $amount)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query generateRandomTokenID {\n    randomizeNFTTokenID {\n      status\n      message\n      tokenId\n    }\n  }\n"): (typeof documents)["\n  query generateRandomTokenID {\n    randomizeNFTTokenID {\n      status\n      message\n      tokenId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetUser($walletAddress: String!) {\n    getUser(walletAddress: $walletAddress) {\n      id\n      username\n      avatar\n      email\n      referral\n      country\n      walletAddress\n      kyc\n      iban\n      nft {\n        tokenId\n        isMinted\n        feeStatus\n        feeHash\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUser($walletAddress: String!) {\n    getUser(walletAddress: $walletAddress) {\n      id\n      username\n      avatar\n      email\n      referral\n      country\n      walletAddress\n      kyc\n      iban\n      nft {\n        tokenId\n        isMinted\n        feeStatus\n        feeHash\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query recheckNFTTokenID($token: Int!) {\n    recheckNFTTokenID(tokenId: $token) {\n      status\n      message\n      tokenId\n    }\n  }\n"): (typeof documents)["\n  query recheckNFTTokenID($token: Int!) {\n    recheckNFTTokenID(tokenId: $token) {\n      status\n      message\n      tokenId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query checkUserHoldsNFT($walletAddress: String!) {\n    checkUserHoldsNFT(walletAddress: $walletAddress)\n  }\n"): (typeof documents)["\n  query checkUserHoldsNFT($walletAddress: String!) {\n    checkUserHoldsNFT(walletAddress: $walletAddress)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query RewardsMeta {\n    rewardsMeta {\n      directReferenceCount\n      networkReferenceCount\n      withdrawable\n      referralCode\n      minWithdrawalAmount\n    }\n  }\n"): (typeof documents)["\n  query RewardsMeta {\n    rewardsMeta {\n      directReferenceCount\n      networkReferenceCount\n      withdrawable\n      referralCode\n      minWithdrawalAmount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Rewards($input: RewardListInput!) {\n    rewards(input: $input) {\n      rewards {\n        id\n        referrer {\n          id\n          username\n          avatar\n          email\n          referral\n          country\n          walletAddress\n          kyc\n          nft {\n            tokenId\n            isMinted\n            feeStatus\n            feeHash\n          }\n        }\n        rewardType\n        value\n        comment\n        timestamp\n      }\n      nextCursor\n    }\n  }\n"): (typeof documents)["\n  query Rewards($input: RewardListInput!) {\n    rewards(input: $input) {\n      rewards {\n        id\n        referrer {\n          id\n          username\n          avatar\n          email\n          referral\n          country\n          walletAddress\n          kyc\n          nft {\n            tokenId\n            isMinted\n            feeStatus\n            feeHash\n          }\n        }\n        rewardType\n        value\n        comment\n        timestamp\n      }\n      nextCursor\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CashbackMeta {\n    cashBackMeta {\n      availableCashBack\n      totalCashBackEarned\n      minClaimAmount\n    }\n  }\n"): (typeof documents)["\n  query CashbackMeta {\n    cashBackMeta {\n      availableCashBack\n      totalCashBackEarned\n      minClaimAmount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery CashBackList($input: CashBackInput!) {\n  cashBackList(input: $input) {\n    cashBack {\n      id\n      type\n      amount\n      hash\n      timestamp\n      comment\n    }\n    nextCursor\n  }\n}\n"): (typeof documents)["\nquery CashBackList($input: CashBackInput!) {\n  cashBackList(input: $input) {\n    cashBack {\n      id\n      type\n      amount\n      hash\n      timestamp\n      comment\n    }\n    nextCursor\n  }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateAuthChallenge {\n    createAuthChallenge {\n      challenge\n      deadline\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAuthChallenge {\n    createAuthChallenge {\n      challenge\n      deadline\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DepositBanks {\n    depositBanks {\n      CHF {\n        account\n        bank\n        BIC\n        payee\n      }\n      EUR {\n        account\n        bank\n        BIC\n        payee\n        payeeAddress\n        remark\n      }\n    }\n  }\n"): (typeof documents)["\n  query DepositBanks {\n    depositBanks {\n      CHF {\n        account\n        bank\n        BIC\n        payee\n      }\n      EUR {\n        account\n        bank\n        BIC\n        payee\n        payeeAddress\n        remark\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Contacts {\n    contacts {\n      EUR {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n      CHF {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n      GBP {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n      USD {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n    }\n  }\n"): (typeof documents)["\n  query Contacts {\n    contacts {\n      EUR {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n      CHF {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n      GBP {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n      USD {\n        id\n        name\n        account\n        bank\n        country\n        lastPaymentDate\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery GetCardInfo {\n  getCardInfo {\n    cardNumber\n    cardHolder\n    cvv\n    expiry\n    securePassword\n    securityInfo {\n      contactlessEnabled\n      withdrawalEnabled\n      internetPurchaseEnabled\n      overallLimitsEnabled\n    }\n    contactLess {\n      used\n      available\n      max\n      dailyUsed\n      dailyAvailable\n      dailyMax\n    }\n    withdrawal {\n      used\n      available\n      max\n      dailyUsed\n      dailyAvailable\n      dailyMax\n    }\n    internetPurchase {\n      used\n      available\n      max\n      dailyUsed\n      dailyAvailable\n      dailyMax\n    }\n    transactionLimits {\n      purchase\n      withdrawal\n      internetPurchase\n      contactless\n    }\n    accountLimits {\n      restartDate\n      used\n      available\n      max\n    }\n  }\n}\n"): (typeof documents)["\nquery GetCardInfo {\n  getCardInfo {\n    cardNumber\n    cardHolder\n    cvv\n    expiry\n    securePassword\n    securityInfo {\n      contactlessEnabled\n      withdrawalEnabled\n      internetPurchaseEnabled\n      overallLimitsEnabled\n    }\n    contactLess {\n      used\n      available\n      max\n      dailyUsed\n      dailyAvailable\n      dailyMax\n    }\n    withdrawal {\n      used\n      available\n      max\n      dailyUsed\n      dailyAvailable\n      dailyMax\n    }\n    internetPurchase {\n      used\n      available\n      max\n      dailyUsed\n      dailyAvailable\n      dailyMax\n    }\n    transactionLimits {\n      purchase\n      withdrawal\n      internetPurchase\n      contactless\n    }\n    accountLimits {\n      restartDate\n      used\n      available\n      max\n    }\n  }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery CardLimits {\n  cardLimits {\n    restartDate\n    used\n    available\n    max\n  }\n}\n"): (typeof documents)["\nquery CardLimits {\n  cardLimits {\n    restartDate\n    used\n    available\n    max\n  }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nquery Transactions($input: TransactionListInput!) {\n  transactions(input: $input) {\n    title\n    subtitle\n    amount\n    timestamp\n    image\n    txId\n    from\n    to\n    cashbackAmount\n  }\n}\n"): (typeof documents)["\nquery Transactions($input: TransactionListInput!) {\n  transactions(input: $input) {\n    title\n    subtitle\n    amount\n    timestamp\n    image\n    txId\n    from\n    to\n    cashbackAmount\n  }\n}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getUploadUrl($input: UploadFileInput!) {\n    upload(input: $input)\n  }\n"): (typeof documents)["\n  query getUploadUrl($input: UploadFileInput!) {\n    upload(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;