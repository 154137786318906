import type { User } from "@/graphql/graphql";
import mixpanel from "mixpanel-browser";

class Analytics {
  private mixpanelToken = import.meta.env.VITE_APP_MIXPANEL_TOKEN;
  private enableAnalytics = import.meta.env.VITE_ENABLE_ANALYTICS === "true";

  init() {
    if (!this.enableAnalytics) {
      return;
    }

    mixpanel.init(this.mixpanelToken, {
      debug: !this.enableAnalytics,
      ignore_dnt: true,
      secure_cookie: true,
      persistence: "cookie",
      api_host: "https://api-eu.mixpanel.com",
    });
  }

  identify(user: User) {
    if (!this.enableAnalytics) {
      return;
    }

    mixpanel.identify(user.walletAddress.toLowerCase());

    mixpanel.people.set(user);
  }

  trackPageView() {
    if (!this.enableAnalytics) {
      return;
    }

    mixpanel.track_pageview();
  }

  track(eventName: string, properties: Record<string, unknown> = {}) {
    if (!this.enableAnalytics) {
      return;
    }
    mixpanel.track(eventName, properties, {});
  }

  reset() {
    if (!this.enableAnalytics) {
      return;
    }
    mixpanel.reset();
  }
}

export const analytics = new Analytics();
