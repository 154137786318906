import { graphql } from "@/graphql";
import type { Country } from "@/graphql/graphql";
import { graphqlClient } from "@/lib/query-client";
import { useQuery } from "@tanstack/react-query";

const countriesDocument = graphql(/* GraphQL */ `
  query Fiat24Countries {
    fiat24Countries {
      eligibleDomiciles {
        name
        alpha3
        phoneCode
        alpha2
      }
      eligibleDomicilesForCards {
        name
        alpha3
        phoneCode
        alpha2
      }
      blacklistNationalities {
        name
        alpha3
        phoneCode
        alpha2
      }
      nfcPassports {
        name
        alpha3
        phoneCode
        alpha2
      }
      nfcNationalIDs {
        name
        alpha3
        phoneCode
        alpha2
      }
      lowLimitDomiciles {
        name
        alpha3
        phoneCode
        alpha2
      }
    }
  }
`);

export const useCountriesQueryKey = ["Countries"];

const useCountries = () => {
  return useQuery({
    queryKey: useCountriesQueryKey,
    queryFn: () => graphqlClient.request(countriesDocument),
    select(data) {
      const {
        fiat24Countries: { eligibleDomiciles },
      } = data;

      const countryMap = new Map<string, Country>();

      for (const country of eligibleDomiciles) {
        countryMap.set(country.alpha3.toLowerCase(), country);
      }

      return {
        ...data,
        countryMap,
      };
    },
    staleTime: 1000 * 60 * 60,
    retry: 3,
  });
};

export default useCountries;
