import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/_authdependant/create-profile")({
  validateSearch: (search) =>
    z
      .object({
        token: z.number(),
      })
      .parse(search),
});
