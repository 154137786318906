import { THEME_KEY } from "@/constants/storage-constants";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocalStorage, useMediaQuery } from "usehooks-ts";

type AvailableTheme = "dark" | "light";
const availableThemes = ["dark", "light"];

interface ThemeProviderProps {
  children: React.ReactNode;
}

type ThemeProviderContextType = {
  theme: AvailableTheme;
  setTheme: (theme: AvailableTheme) => void;
};

const ThemeProviderContext = createContext<ThemeProviderContextType>({
  theme: "light",
  setTheme: (_theme: AvailableTheme) => {},
});

export const ThemeProvder = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<AvailableTheme>("light");

  const [_, setThemeInStorage] = useLocalStorage(THEME_KEY, "light");

  const isPreferredColorDark = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    let localTheme = localStorage.getItem(THEME_KEY);
    if (localTheme !== null) {
      if (localTheme.startsWith('"') && localTheme.endsWith('"')) {
        localTheme = localTheme.slice(1, -1);
      }
      if (availableThemes.includes(localTheme as AvailableTheme)) {
        updateLocal(localTheme as AvailableTheme);
      }
    } else {
      if (isPreferredColorDark) {
        updateLocal("dark");
      } else {
        updateLocal("light");
      }
    }
  }, [isPreferredColorDark]);

  const sync = useCallback((theme: AvailableTheme) => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      document.documentElement.classList.remove("light");
    }
    if (theme === "light") {
      document.documentElement.classList.add("light");
      document.documentElement.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    sync(theme);
  }, [sync, theme]);

  const changeTheme = useCallback(
    (theme: AvailableTheme) => {
      updateLocal(theme);
      if (!document.startViewTransition) return sync(theme);
      document.startViewTransition(() => sync(theme));
    },
    [sync]
  );

  const updateLocal = useCallback(
    (theme: AvailableTheme) => {
      setThemeInStorage(theme);
      setTheme(theme);
    },
    [setThemeInStorage]
  );
  
  return (
    <ThemeProviderContext.Provider value={{ theme, setTheme: changeTheme }}>
      {children}
    </ThemeProviderContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
