import { graphql } from "@/graphql";

export const generateRandomTokenIdDocument = graphql(/* GraphQL */ `
  query generateRandomTokenID {
    randomizeNFTTokenID {
      status
      message
      tokenId
    }
  }
`);

export const getUserDocument = graphql(/* GraphQL */ `
  query GetUser($walletAddress: String!) {
    getUser(walletAddress: $walletAddress) {
      id
      username
      avatar
      email
      referral
      country
      walletAddress
      kyc
      iban
      nft {
        tokenId
        isMinted
        feeStatus
        feeHash
      }
    }
  }
`);

export const recheckNFTTokenIdDocument = graphql(/* GraphQL */ `
  query recheckNFTTokenID($token: Int!) {
    recheckNFTTokenID(tokenId: $token) {
      status
      message
      tokenId
    }
  }
`);

export const checkIfUserHoldsNFT = graphql(/* GraphQL */ `
  query checkUserHoldsNFT($walletAddress: String!) {
    checkUserHoldsNFT(walletAddress: $walletAddress)
  }
`);

export const fetchRewardsMetadata = graphql(/* GraphQL */ `
  query RewardsMeta {
    rewardsMeta {
      directReferenceCount
      networkReferenceCount
      withdrawable
      referralCode
      minWithdrawalAmount
    }
  }
`);

export const rewardsListDocument = graphql(/* GraphQL */ `
  query Rewards($input: RewardListInput!) {
    rewards(input: $input) {
      rewards {
        id
        referrer {
          id
          username
          avatar
          email
          referral
          country
          walletAddress
          kyc
          nft {
            tokenId
            isMinted
            feeStatus
            feeHash
          }
        }
        rewardType
        value
        comment
        timestamp
      }
      nextCursor
    }
  }
`);

export const fetchCashbackMetadata = graphql(/* GraphQL */ `
  query CashbackMeta {
    cashBackMeta {
      availableCashBack
      totalCashBackEarned
      minClaimAmount
    }
  }
`);

export const cashbackListDocument = graphql(/* GraphQL */ `
query CashBackList($input: CashBackInput!) {
  cashBackList(input: $input) {
    cashBack {
      id
      type
      amount
      hash
      timestamp
      comment
    }
    nextCursor
  }
}
`);

export const createAuthChallengeDoc = graphql(/* GraphQL */ `
  mutation CreateAuthChallenge {
    createAuthChallenge {
      challenge
      deadline
    }
  }
`);

export const getDepositBankDetailsDocument = graphql(/* GraphQL */ `
  query DepositBanks {
    depositBanks {
      CHF {
        account
        bank
        BIC
        payee
      }
      EUR {
        account
        bank
        BIC
        payee
        payeeAddress
        remark
      }
    }
  }
`);

export const getContactsDocument = graphql(/* GraphQL */ `
  query Contacts {
    contacts {
      EUR {
        id
        name
        account
        bank
        country
        lastPaymentDate
      }
      CHF {
        id
        name
        account
        bank
        country
        lastPaymentDate
      }
      GBP {
        id
        name
        account
        bank
        country
        lastPaymentDate
      }
      USD {
        id
        name
        account
        bank
        country
        lastPaymentDate
      }
    }
  }
`);

export const getCardInfoDocument = graphql(/* GraphQL */ `
query GetCardInfo {
  getCardInfo {
    cardNumber
    cardHolder
    cvv
    expiry
    securePassword
    securityInfo {
      contactlessEnabled
      withdrawalEnabled
      internetPurchaseEnabled
      overallLimitsEnabled
    }
    contactLess {
      used
      available
      max
      dailyUsed
      dailyAvailable
      dailyMax
    }
    withdrawal {
      used
      available
      max
      dailyUsed
      dailyAvailable
      dailyMax
    }
    internetPurchase {
      used
      available
      max
      dailyUsed
      dailyAvailable
      dailyMax
    }
    transactionLimits {
      purchase
      withdrawal
      internetPurchase
      contactless
    }
    accountLimits {
      restartDate
      used
      available
      max
    }
  }
}
`);

export const getCardLimitsDocument = graphql(/* GraphQL */ `
query CardLimits {
  cardLimits {
    restartDate
    used
    available
    max
  }
}
`);

export const getTransactionsDocument = graphql(/* GraphQL */ `
query Transactions($input: TransactionListInput!) {
  transactions(input: $input) {
    title
    subtitle
    amount
    timestamp
    image
    txId
    from
    to
    cashbackAmount
  }
}
`);

export const getUploadUrlDocument = graphql(/* GraphQL */ `
  query getUploadUrl($input: UploadFileInput!) {
    upload(input: $input)
  }
`);

export const updateUserDocument = graphql(/* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`);
