import { settingsNavItems } from "@/data/settings";
import { cn } from "@/lib/utils";
import { useTheme } from "@/providers/ThemeProvider";
import { Link, useRouterState } from "@tanstack/react-router";
import { ChevronLeft } from "lucide-react";
import { FormattedMessage } from "react-intl";

export default function SettingsSideNav() {
  const routerState = useRouterState();

  const { theme } = useTheme();

  return (
    <>
      <ul className="mb-7 flex w-full flex-col items-center">
        {settingsNavItems.map((navItem) => {
          const Image = navItem.image;
          return (
            <li
              key={navItem.href}
              className="group-data-[expanded=true]:w-full"
            >
              <Link
                preload={false}
                search={{}}
                to={navItem.href}
                className={cn(
                  "tooltip-hover relative flex items-center rounded-xl bg-transparent px-6 py-5 hover:bg-transparent",
                  "group-data-[expanded=true]:w-full group-data-[expanded=true]:gap-x-4 group-data-[expanded=true]:p-5",
                  {
                    "bg-[#6332CB] hover:bg-[#6332CB]":
                      routerState.location.pathname === navItem.href,
                  }
                )}
              >
                <Image
                  pathFillColor={
                    theme === "dark"
                      ? "#FFFFFF"
                      : routerState.location.pathname === navItem.href
                        ? "#ffffff"
                        : "#3D3D3D"
                  }
                  fill={
                    navItem.href === "/my/settings/debit-card"
                      ? "none"
                      : theme === "dark"
                        ? "#FFFFFF"
                        : routerState.location.pathname === navItem.href
                          ? "#fff"
                          : "#3D3D3D"
                  }
                  width={24}
                  height={24}
                />
                <p
                  className={cn(
                    "hidden font-medium font-poppins text-[#3D3D3D] text-base dark:text-white",
                    "group-data-[expanded=true]:block",
                    {
                      "text-white":
                        routerState.location.pathname === navItem.href,
                    }
                  )}
                >
                  {navItem.name}
                </p>
                <span className="-top-2 -translate-x-1/2 absolute left-1/2 block whitespace-nowrap rounded-2xl bg-gray-700/80 px-2 py-1 text-xs opacity-0 group-data-[expanded=true]:hidden">
                  {navItem.name}
                </span>
              </Link>
            </li>
          );
        })}
        <li className="group-data-[expanded=true]:w-full">
          <Link
            preload={false}
            search={{}}
            to="/my/dashboard"
            className={cn(
              "tooltip-hover relative flex items-center rounded-xl bg-transparent px-6 py-5 text-[#3D3D3D] transition-colors hover:bg-transparent dark:text-white",
              "group-data-[expanded=true]:w-full group-data-[expanded=true]:gap-x-4 group-data-[expanded=true]:p-5"
            )}
          >
            <ChevronLeft width="32" height="32" />
            <p
              className={cn(
                "hidden font-medium font-poppins text-[#3D3D3D] text-base transition-colors dark:text-white",
                "group-data-[expanded=true]:block"
              )}
            >
              <FormattedMessage id="back" defaultMessage="Back" />
            </p>
            <span className="-top-2 -translate-x-1/2 absolute left-1/2 block whitespace-nowrap rounded-2xl bg-gray-700/80 px-2 py-1 text-white text-xs opacity-0 group-data-[expanded=true]:hidden">
              <FormattedMessage id="back" defaultMessage="Back" />
            </span>
          </Link>
        </li>
      </ul>
    </>
  );
}
