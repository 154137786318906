import { wagmiConfig } from "@/lib/wagmi";
import { WagmiProvider } from "wagmi";

export default function Web3Provider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <WagmiProvider reconnectOnMount config={wagmiConfig}>
      {children}
    </WagmiProvider>
  );
}
