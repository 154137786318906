import { fiat24CurrencyToAddressMap } from "@/constants/general";
import { currencyAbi } from "@/data/currency-abi";
import type { Fiat24CurrencyType } from "@/types/general";
import { useReadContracts } from "wagmi";

export default function useGetAllBalances(address: `0x${string}`) {
  const { data, isPending, error, queryKey } = useReadContracts({
    allowFailure: false,
    contracts: [
      {
        abi: currencyAbi,
        address: fiat24CurrencyToAddressMap.get("eur"),
        functionName: "balanceOf",
        args: [address],
      },
      {
        abi: currencyAbi,
        address: fiat24CurrencyToAddressMap.get("usd"),
        functionName: "balanceOf",
        args: [address],
      },
      {
        abi: currencyAbi,
        address: fiat24CurrencyToAddressMap.get("chf"),
        functionName: "balanceOf",
        args: [address],
      },
      {
        abi: currencyAbi,
        address: fiat24CurrencyToAddressMap.get("cnh"),
        functionName: "balanceOf",
        args: [address],
      },
    ],
    query: {
      staleTime: 10 * 60 * 1000, // 10 minutes
      refetchInterval: 10 * 60 * 1000, // 10 minutes
      enabled: address !== undefined,
    },
  });

  return {
    isPending,
    data: new Map<Fiat24CurrencyType, number | undefined>([
      ["eur", data ? Number(data[0]) / 100 : undefined],
      ["usd", data ? Number(data[1]) / 100 : undefined],
      ["chf", data ? Number(data[2]) / 100 : undefined],
      ["cnh", data ? Number(data[3]) / 100 : undefined],
    ]),
    error,
    queryKey,
  };
}
