import { cva } from "class-variance-authority";
import type { VariantProps } from "class-variance-authority";
import { RotateCw } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-md font-medium text-sm ring-offset-white transition-colors disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-none dark:focus-visible:ring-neutral-300 focus-visible:ring-2 focus-visible:ring-neutral-950 dark:ring-offset-neutral-950 focus-visible:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "bg-neutral-900 text-neutral-50 dark:bg-neutral-50 dark:hover:bg-neutral-50/90 hover:bg-neutral-900/90 dark:text-neutral-900",
        destructive:
          "bg-red-500 text-neutral-50 dark:bg-red-900 dark:hover:bg-red-900/90 hover:bg-red-500/90 dark:text-neutral-50",
        outline:
          "border border-neutral-200 bg-white dark:border-neutral-800 dark:bg-neutral-950 dark:hover:bg-neutral-800 hover:bg-neutral-100 dark:hover:text-neutral-50 hover:text-neutral-900",
        secondary:
          "bg-neutral-100 text-neutral-900 dark:bg-neutral-800 dark:hover:bg-neutral-800/80 hover:bg-neutral-100/80 dark:text-neutral-50",
        ghost:
          "dark:hover:bg-neutral-800 hover:bg-neutral-100 dark:hover:text-neutral-50 hover:text-neutral-900",
        link: "text-neutral-900 underline-offset-4 dark:text-neutral-50 hover:underline",
        primary:
          "bg-primary text-white dark:bg-primary dark:hover:bg-primary/90 hover:bg-primary/90 dark:text-neutral-50",
        plain: "",
      },
      size: {
        default: "px-4 py-2",
        sm: "rounded-md px-3",
        lg: "rounded-md px-8",
        icon: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  loadingText?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      isLoading,
      disabled,
      children,
      loadingText = "Loading...",
      type,
      ...props
    },
    ref
  ) => {
    return (
      <button
        type={type}
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={isLoading || disabled}
        aria-disabled={isLoading || disabled}
        aria-busy={isLoading}
        {...props}
      >
        {isLoading ? (
          <>
            <RotateCw className="mr-2 animate-spin" />
            {loadingText}
          </>
        ) : (
          children
        )}
      </button>
    );
  }
);
Button.displayName = "Button";

export { Button };
