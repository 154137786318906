import type { SVGProps } from "react";

const Settings = ({
  pathFillColor,
  ...props
}: SVGProps<SVGSVGElement> & { pathFillColor?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <title>Settings</title>
      <path d="M11.8516 2.92577L12.3479 3.79243C12.5352 4.11985 12.8059 4.39175 13.1325 4.58044C13.4592 4.76912 13.83 4.86783 14.2072 4.86651H15.1849C15.5609 4.86498 15.9305 4.96283 16.2565 5.15014C16.5824 5.33745 16.8531 5.60756 17.0411 5.93313C17.2291 6.2587 17.3277 6.62816 17.3269 7.0041C17.3262 7.38004 17.2261 7.7491 17.0368 8.07391L16.5331 8.93317C16.3438 9.25949 16.2441 9.63002 16.2441 10.0072C16.2441 10.3845 16.3438 10.755 16.5331 11.0813L17.0368 11.9406C17.2242 12.2652 17.3228 12.6335 17.3226 13.0083C17.3224 13.3832 17.2235 13.7514 17.0357 14.0758C16.848 14.4002 16.578 14.6695 16.2531 14.8564C15.9282 15.0433 15.5598 15.1413 15.1849 15.1406H14.2072C13.83 15.1393 13.4592 15.238 13.1325 15.4267C12.8059 15.6153 12.5352 15.8872 12.3479 16.2147L11.8516 17.0813C11.665 17.4077 11.3954 17.6789 11.0702 17.8675C10.745 18.0562 10.3757 18.1555 9.99975 18.1555C9.6238 18.1555 9.25452 18.0562 8.92932 17.8675C8.60412 17.6789 8.33455 17.4077 8.1479 17.0813L7.6516 16.2147C7.46432 15.8872 7.19357 15.6153 6.86695 15.4267C6.54034 15.238 6.16954 15.1393 5.79234 15.1406H4.81457C4.43863 15.1421 4.06897 15.0443 3.74302 14.8569C3.41706 14.6696 3.14639 14.3995 2.95842 14.074C2.77045 13.7484 2.67185 13.3789 2.6726 13.003C2.67335 12.627 2.77344 12.258 2.96271 11.9332L3.46642 11.0739C3.65569 10.7476 3.75537 10.3771 3.75537 9.99984C3.75537 9.62261 3.65569 9.25208 3.46642 8.92577L2.9479 8.06651C2.75796 7.74061 2.65783 7.37017 2.65771 6.99296C2.6576 6.61575 2.7575 6.24525 2.94724 5.91923C3.13698 5.59321 3.40977 5.32333 3.7378 5.1371C4.06583 4.95087 4.43738 4.85494 4.81457 4.8591H5.79234C6.16881 4.86117 6.53912 4.76358 6.86567 4.57623C7.19222 4.38889 7.46337 4.11847 7.6516 3.79243L8.1479 2.92577C8.33455 2.59942 8.60412 2.32819 8.92932 2.13955C9.25452 1.95091 9.6238 1.85156 9.99975 1.85156C10.3757 1.85156 10.745 1.95091 11.0702 2.13955C11.3954 2.32819 11.665 2.59942 11.8516 2.92577Z" />
      <path d="M9.99979 12.8072C11.5503 12.8072 12.8072 11.5503 12.8072 9.99979C12.8072 8.4493 11.5503 7.19238 9.99979 7.19238C8.4493 7.19238 7.19238 8.4493 7.19238 9.99979C7.19238 11.5503 8.4493 12.8072 9.99979 12.8072Z" />
    </svg>
  );
};

export default Settings;
